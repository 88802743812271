import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { PostService } from '../services/post.service';
import { TokenStorageService } from '../services/token-storage.service';
import { UtilityService } from '../services/utility.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from '../services/excel.service';
import { DashboardDetailsComponent } from '../dashboard/dashboard-details/dashboard-details.component';
import { MatDialog } from '@angular/material/dialog';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.css']
})
export class ApprovedComponent implements OnInit {
  approvedForm : UntypedFormGroup;

  displayedColumns: string[] = ['id', 'name','cid','mobile', 'dob','regTypea','cardType','file','created_at','registration_number','author','cardnumber','action','actionbtn'];
  dataSource: MatTableDataSource<any>;
  
  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
  showLoader: boolean = false;
  userLoggedIn: boolean = false;
  data: any;
  updateBtn: boolean = false;

  @ViewChild('htmlData') htmlData!: ElementRef;
  filePath: any;
  
  constructor(  
    private utility: UtilityService,
    private postService: PostService,
    private authService: AuthService,
    private excelService:ExcelService,
    private dialog: MatDialog,
    private tokenStorageService: TokenStorageService
    ) { }
  

  ngOnInit(): void {
    this.filePath = environment.attachmentPath;
    this.showLoader = true;
    this.userLoggedIn = true;
   
    this.approvedForm = new UntypedFormGroup(
      {
        fromDate: new UntypedFormControl('', [Validators.required]),
        toDate: new UntypedFormControl('', [Validators.required]),
      }
    );

    // this.getApprovedApplications();
  }

  submit(){
    const fromdate = (this.approvedForm.value.fromDate);
    const todate = this.approvedForm.value.toDate;
    
    this.postService.getApprovedApplicationsDate(
      fromdate.toISOString().slice(0,10),
      todate.toISOString().slice(0,10)
    )
    .subscribe({
      next: (res)=>{
        // console.log(res);
        this.data = res['data'];
        this.dataSource = new MatTableDataSource(res['data']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }
  
  getApprovedApplications(){
    this.postService.getApprovedApplications()
    .subscribe({
      next: (res)=>{
        console.log(res);

        this.data = res['data'];
        this.dataSource = new MatTableDataSource(res['data']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: (err)=>{
        this.authService.logout()
        .subscribe({
            next: () => {
              this.utility.successNotification("Session timeout.Login again.");
              this.tokenStorageService.signOut();
              this.reloadPage();
            }
        });
      }
    });
  }

  reloadPage(): void {
    window.location.href=environment.webUrl;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editApplication(row: any){
    this.dialog.open(DashboardDetailsComponent,{
      width: environment.width,
      height: '80%',
      data: row
    }).afterClosed().subscribe(val=>{
     
    });
  }
  
  public openPDF(): void {
    let DATA: any = document.getElementById('htmlData');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('file.pdf');
    });
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.data, 'file');
 }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { UtilityService } from '../services/utility.service';
import { TokenStorageService } from '../services/token-storage.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SmartcardRegistrationService } from '../services/smartcardregistration.service';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
  
  export class TrackComponent implements OnInit {
    
    userForm : UntypedFormGroup;
    showdata = false;
    remarkShow = false;
    checkSum: string;
    data: any;
    appStatus: any;

    constructor(
      private formBuilder: UntypedFormBuilder,
      public  utilityService: UtilityService,
      private spinner: NgxSpinnerService,
      private smartcardRegistrationService: SmartcardRegistrationService
      ) { 
      this.userForm = this.formBuilder.group({
        application:  ['', [Validators.required]],
      });
    }
    
    ngOnInit(): void {
    }
  
    trackApplication(){
      this.spinner.show();
      this.smartcardRegistrationService.trackApplication(this.userForm.value.application)
      .subscribe({
          next: (res)=>{

            if(res['data'].length ==0){
              this.showdata = false;
              this.utilityService.errorNotification("No record found.Check your application number")
            }else{
              this.data = res['data'][0];
              if(res['data'][0].action =='Under Process'){
                 this.appStatus = 'Under Process';
                 this.remarkShow = false;
              }else{
                this.appStatus = res['data'][0].action;
                this.remarkShow = true;
              }
              this.showdata = true;
            }
            this.spinner.hide();
        },
        error:()=>{
          this.spinner.hide();
          this.showdata = false;
        }
      })
    }
  
    reloadPage(): void {
      // window.location.reload();
      window.location.href=environment.webUrl;
    }
  }
  
import { Injectable } from '@angular/core';
import { EncrDecrService } from './EncrDecrService';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-id';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private EncrDecr: EncrDecrService) { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUserData(userData: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, userData);
  }

  public getUser(): any {
    const data: any = window.sessionStorage.getItem(USER_KEY);
    
    if(data !== null){
      
      var decryptedData = this.EncrDecr.get('3738^%$!#@^@1ERF', data);
      var dataR = Array.from(decryptedData.split('|'));
      var user = [{
        'user_id'     : dataR[0],
        'user_name'   : dataR[1],
        'user_email'  : dataR[2],
        'user_role'   : dataR[3],
        'userPanelAccess':dataR[4],
        'rolePanelAccess':dataR[5],
        'permissionPanelAccess':dataR[6],
        'postPanelAccess':dataR[7],
        'isLoggedIn': dataR[8]
      }];
      return user[0];
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class UtilityService {
  loader: any;


  public tinyAlert() {
    Swal.fire('Hey there!');
  }

  errorNotification(message) {
    Swal.fire('', message, 'error');
  }

  successNotification(message) {
    Swal.fire('', message, 'success');
  }

  alertConfirmation() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, go ahead.',
      cancelButtonText: 'No, let me think',
    }).then((result) => {
      if (result.value) {
        Swal.fire('Removed!', 'Product removed successfully.', 'success');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Product still in our database.)', 'error');
      }
    });
  }
  
  successConfirmation($msg,$icon) {
    Swal.fire({
      title: 'Information!',
      text: $msg,
      icon: $icon,
      showCancelButton: false,
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.value) {
        this.reloadPage();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.reloadPage();
      }
    });
  }

  reloadPage(): void {
    window.location.href=environment.webUrl;
  }
}

<div class="footer text-center" style="background-color: #cfeef0">
    <div class="row">
        <div class="col-md-4">
            <h5 class="title"> Address </h5>
            City Bus Service Office<br> Lungtenzampa
            <br> Thimphu. Bhutan<br> 11001
            <br>
        </div>
        <div class="col-md-4">
            <h5 class="title"> Phone </h5>

            Office : +975 02 325734/ 02 338748<br>
        </div>
        <div class="col-md-4">
            <h5 class="title"> Website </h5>
            www.citybusservice.bt
        </div>
    </div>

    <div class="container text-center">
        <div class="mt-3 copyright">
            <strong>Copyright &copy; 2022.
        All rights reserved. </strong>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
 }

 constructor(private httpClient: HttpClient) { }

  getUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.ApiURL+'user')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getRoleName(roleId): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'user/getRole/'+roleId)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(user): Observable<User> {
    return this.httpClient.post<User>(environment.ApiURL+'user', JSON.stringify(user), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  find(id): Observable<User> {
    return this.httpClient.get<User>(environment.ApiURL +'user/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getRole(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL +'user/roles')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id, user): Observable<User> {
    return this.httpClient.put<User>(environment.ApiURL +'user/' + id, JSON.stringify(user), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<User>(environment.ApiURL + 'user/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

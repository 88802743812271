export const environment = {
  production: true,
  authApiURL: 'https://smartcardregistration.bt/smartcard_backend/public/api/auth/',
  ApiURL: 'https://smartcardregistration.bt/smartcard_backend/public/api/',
  MaxFileSize: 5000000,
  ImageAllowedType: ['image/png', 'image/jpeg','image/jpeg'],
  AttachmentAllowedType: ['image/png', 'image/jpeg','application/pdf'],
  attachmentPath: 'https://smartcardregistration.bt/smartcard_backend/public/uploads/smartcard/',
  webUrl: 'https://smartcardregistration.bt',
  // webUrl: 'https://smartcardregistration.bt:4200',
  width: '80%'
};

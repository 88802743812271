import { Component, OnInit,  ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utility.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { UserIndexComponent } from '../user-index/user-index.component';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})

export class UserDashboardComponent implements OnInit {
  
  displayedColumns: string[] = ['id', 'name', 'email', 'role_id','created_at','action'];
  dataSource: MatTableDataSource<any>;
  showLoader: boolean;

  canCreateUser: boolean = false;
  canEditUser: boolean = false;
  canDeleteUser: boolean = false;
  userLoggedIn: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private utilityService: UtilityService,
    private userService: UserService,
    private SpinnerService: NgxSpinnerService,
  ) {   }
  
  ngOnInit(): void {
    this.getAllUsers(true);
  }

  ngAfterViewInit() {
    if(this.userLoggedIn){
      this.paginator.page
        .pipe(
            tap(() => this.getAllUsers(true))
        )
        .subscribe();
    }
  }

  editUser(row: any){
    this.dialog.open(UserIndexComponent,{
      width: '80%',
     height: '80%',
      data: row
    }).afterClosed().subscribe(val=>{
      if(val === 'update'){
        this.getAllUsers(false);
      }
    });
  }

  deleteUser(content: any, id: number)
  {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
        if (result === 'yes') {  
          this.deleteUserRecord(id);  
        }  
      }, (reason) => {  
      });  
  }

  deleteUserRecord(id){
    this.userService.delete(id)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully deleted user");
        this.getAllUsers(false);
      },
      error : ()=>{
        this.utilityService.errorNotification("Error in deleting the user");
      }
    })
  }
  
  addUserDialog() {
    this.dialog.open(UserIndexComponent, {
     width: '80%',
     height: '80%'
    }).afterClosed().subscribe(val=>{
      this.getAllUsers(false);
    })
  }

  getAllUsers(displayLoader){
    if(displayLoader){
      this.showLoader = true;
    }else{
      this.showLoader = false;
    }

    this.SpinnerService.show(); 

    if(this.tokenStorageService.getToken() === null){
      this.SpinnerService.hide(); 
      this.redirectPage();
    }else{
      this.userLoggedIn = true;
    }
  
    this.userService.getUsers()
    .subscribe({
      next: (res)=>{
        this.SpinnerService.hide(); 
        this.dataSource = new MatTableDataSource(res['users']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if(res['userPermission']['createUser'] == 1){
          this.canCreateUser = true;
        }
        if(res['userPermission']['editUser'] == 1){
          this.canEditUser = true;
        }
        if(res['userPermission']['deleteUser'] == 1){
          this.canDeleteUser = true;
        }
      },
      error: (err)=>{
        console.log("Error ....")
      }
    })
  }
    
  redirectPage(): void {
    this.tokenStorageService.signOut();
    window.location.href=environment.webUrl;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

<div class="container">
    <h2>{{ actionBtn}} User </h2>
</div>

<div class="table-responsive-sm">
    <form [formGroup]="userForm">
        <div class="form-group">
            <label>First Name</label>
            <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required"> Name is required</div>
            </div>
        </div>
     
        <div class="form-group">
            <label>Email</label>
            <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        
        <div class="form-group">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>

        <div class="form-group">
            <label>Confirm Password</label>
            <input type="password" formControlName="confirmPassword" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
            <div *ngIf="f.confirmPassword.errors.mustMatch">Password and confirm not match</div>
            </div>
        </div>

        <div class="form-group">
            <label for="role_id">Role</label>
            <select formControlName="role_id" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.role_id.errors }">
                <option *ngFor="let r of roles" value="{{r.id}}">
                    {{r.role_name}}
                </option>
            </select>
            <div *ngIf="submitted && f.role_id.errors" class="invalid-feedback">
            <div *ngIf="f.role_id.errors.required"> Role is required</div>
            </div>  
        </div>
        
        <div mat-dialog-action [align]="'end'">
            <button mat-raised-button color="primary" (click)="addUser()">{{ actionBtn}}</button>
            <button style="margin-left:8px; margin-top:8px;" mat-raised-button color="warn" mat-dialog-close>Close</button>
        </div>
    </form>
</div>

import { Blog } from './blog-type';
export const blogs: Blog[] = [
  {
    id: 1,
    image: 'assets/images/blog/303.jpg',
    heading:
      'Shortlisted Candidates of Sr. Communication Officer.',
    subHeading:
      'Shortlisted Candidates of Sr. Communication Officer of APIC. The details can be find hereShortlisted candidates (Sr. CO) .',
    blogDate: 'Feb 15, 2020',
    blogDetail:
      'Laboris culpa ea est et aliqua amet ullamco Lorem non enim laborum incididunt ex Lorem. Minim sint cupidatat magna esse labore id. Do qui incididunt consectetur nulla aliquip consequat nostrud incididunt occaecat ad. Excepteur nulla adipisicing enim ea occaecat fugiat dolore sunt est et. Eu veniam nostrud sit aute consectetur dolore ipsum fugiat anim duis.',
  },
  {
    id: 2,
    image: '',
    heading:
      'Website Launch',
    subHeading: 'Agency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or before',
    blogDate: 'Feb 19, 2020',
    blogDetail:
      'Agency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or before.',
  },
  {
    id: 3,
    image: 'assets/images/blog/505.jpg',
    heading: 'Vacancy Announcement.',
    subHeading:
      'Agency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or before.',
    blogDate: 'Feb 17, 2020',
    blogDetail:
      'Agency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or beforeAgency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or beforeAgency for Promotion of Indigenous Crafts is inviting suitably qualified candidates for the post of Sr. Communications Officer/Communications Officer. Interested candidates may submit their application to CEO, APIC, RSPN Building 1st Floor, Kawangjansa, on or before.',
  },
  {
    id: 4,
    image: 'assets/images/blog/707.jpg',
    heading:
      'Postponement of Bid Submission',
    subHeading:
      'The bid submission for the development of official website for Bhutan Textile Cooperatives is postponed to April 15, 2022 at 10:30 AM and will be opened on the same day at 3 PM ',
    blogDate: 'Feb 15, 2020',
    blogDetail:
      'The bid submission for the development of official website for Bhutan Textile Cooperatives is postponed to April 15, 2022 at 10:30 AM and will be opened on the same day at 3 PM The bid submission for the development of official website for Bhutan Textile Cooperatives is postponed to April 15, 2022 at 10:30 AM and will be opened on the same day at 3 PM Laboris culpa ea est et aliqua amet ullamco Lorem non enim laborum incididunt ex Lorem. Minim sint cupidatat magna esse labore id. Do qui incididunt consectetur nulla aliquip consequat nostrud incididunt occaecat ad. Excepteur nulla adipisicing enim ea occaecat fugiat dolore sunt est et. Eu veniam nostrud sit aute consectetur dolore ipsum fugiat anim duis.',
  }
];

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, delay } from 'rxjs/operators';
import { Role } from '../model/role';
import { Permission } from '../model/permission';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
 }

 constructor(private httpClient: HttpClient) { }

  getAllRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(environment.ApiURL+'roles')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  // getAllPermissionsList(term: string = null): Observable<Permission[]> {
  //   return this.httpClient.get<Permission[]>(environment.ApiURL+'permissions');
  // }

  getAllPermissions(): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(environment.ApiURL+'permissions')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  findRole(id): Observable<Role> {
    return this.httpClient.get<Role>(environment.ApiURL +'roles/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getRolePermission(){
    return this.httpClient.get<any>(environment.ApiURL +'roles/getRolePermission')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(data): Observable<any> {
    return this.httpClient.post<any>(environment.ApiURL+'roles/', JSON.stringify(data), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id,role): Observable<Role> {
    return this.httpClient.put<Role>(environment.ApiURL +'roles/'+id, JSON.stringify(role), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<Role>(environment.ApiURL + 'roles/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

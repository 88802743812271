import { UtilityService } from './../../services/utility.service';
import { AuthService } from './../../services/auth.service';
import { DashboardDetailsComponent } from './../dashboard-details/dashboard-details.component';
import { PostService } from './../../services/post.service';
import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import * as CanvasJS from '../../../assets/canvasjs/canvasjs.min';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  username: string;
  userLoggedIn: boolean = false;
  showLoader: boolean;
  applications: any;

  displayedColumns: string[] = ['id', 'name','cid','mobile', 'dob','regTypea','cardType','file','created_at','registration_number','action','actionbtn'];
  dataSource: MatTableDataSource<any>;
  
 filePath: any;

  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
    
  constructor( 
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private utility: UtilityService,
    private postService: PostService,
    private dataService: DataService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = true;
    this.showLoader = true;
    this.filePath = environment.attachmentPath;
    this.getApplications();
  }

  getApplications(){
    this.postService.getAllApplications()
    .subscribe({
      next: (res)=>{
        this.dataSource = new MatTableDataSource(res['data']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: (err)=>{
        this.authService.logout()
        .subscribe({
            next: () => {
              this.utility.successNotification("Session timeout.Login again.");
              this.tokenStorageService.signOut();
              this.reloadPage();
            }
        });
      }
    });
  }


  reloadPage(): void {
    window.location.href=environment.webUrl;
  }

  editApplication(row:any){
    this.dialog.open(DashboardDetailsComponent,{
      width: environment.width,
      height: '80%',
      data: row
    }).afterClosed().subscribe(val=>{
      if(val === 'update'){
        this.getApplications();
      }
    });
  }

  barChart(){
    let chart = new CanvasJS.Chart("barchartContainer", {
      animationEnabled: true,
      exportEnabled: false,
      title: {
        text: "Basic Column Chart"
      },
      data: [{
        type: "column",
        dataPoints: [
          { y: 71, label: "Apple" },
          { y: 55, label: "Mango" },
          { y: 50, label: "Orange" },
          { y: 65, label: "Banana" },
          { y: 95, label: "Pineapple" },
          { y: 14, label: "Jackfruit" }
        ]
      }]
    });
    chart.render();
  }

  pieChart(){
    let chart = new CanvasJS.Chart("piechartContainer", {
      theme: "light2",
      animationEnabled: true,
      exportEnabled: false,
      title:{
        text: "Monthly Expense"
      },
      data: [{
        type: "pie",
        showInLegend: true,
        toolTipContent: "<b>{name}</b>: ${y} (#percent%)",
        indexLabel: "{name} - #percent%",
        dataPoints: [
          { y: 450, name: "Food" },
          { y: 120, name: "Insurance" },
          { y: 300, name: "Traveling" },
          { y: 150, name: "Shopping"},
          { y: 250, name: "Others" }
        ]
      }]
    });
      
    chart.render();
  }

  lineChart(){
    let chart = new CanvasJS.Chart("linechartContainer", {
      animationEnabled: true,
      theme: "light2",
      title:{
        text: "Simple Line Chart"
      },
      data: [{        
        type: "line",
            indexLabelFontSize: 16,
        dataPoints: [
          { y: 450 },
          { y: 414},
          { y: 520, indexLabel: "\u2191 highest",markerColor: "red", markerType: "triangle" },
          { y: 460 },
          { y: 450 },
          { y: 500 },
          { y: 480 },
          { y: 480 },
          { y: 410 , indexLabel: "\u2193 lowest",markerColor: "DarkSlateGrey", markerType: "cross" },
          { y: 500 },
          { y: 480 },
          { y: 510 }
        ]
      }]
    });
    chart.render();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

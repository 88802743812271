<div *ngIf="!isLoggedIn">
    <div class="topbar" id="top">
        <div class="header6">
            <div class="container po-relative">
                <nav class="navbar navbar-expand-lg h6-nav-bar">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/images/logos/logo.png" alt="Logo" style="margin-top:8px;" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#h6-info" aria-controls="h6-info" aria-expanded="false">
                        <span class="ti-menu"></span>
                    </button>

                    <div class="collapse navbar-collapse hover-dropdown font-14 ml-auto" id="h6-info">
                        <ul class="navbar-nav ml-auto  align-items-center">

                            <li class="nav-item" [routerLinkActive]="['active']">
                                <a class="nav-link" routerLink="/">
                                    Home
                                </a>
                            </li>


                            <li class="nav-item" [routerLinkActive]="['active']">
                                <a class="nav-link" (click)="contactUs()"> Contact Us</a>
                            </li>


                            <li class="nav-item" [routerLinkActive]="['active']">
                                <a class="nav-link" (click)="register()"> Online Registeration</a>
                            </li>

                            <li class="nav-item" [routerLinkActive]="['active']">
                                <a class="nav-link" (click)="track()"> Track Application</a>
                            </li>

                            <li class="nav-item" [routerLinkActive]="['active']" *ngIf="showLogin">
                                <a class="nav-link" (click)="loginDialog()">Login</a>
                            </li>

                            <li class="nav-item" [routerLinkActive]="['active']" *ngIf="!showLogin">
                                <a class="nav-link" (click)="logout()">Logout</a>
                            </li>

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>
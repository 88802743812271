<div class="row wrap-feature-22">
  <!-- Column -->
  <div class="shadow p-2  col-md-6" data-aos="flip-up" data-aos-duration="1200">
    <img [src]="imagePath" class="rounded img-responsive" alt="" />
  </div>
  <!-- Column -->
  <div class="col-lg-6 no-trans">
    <div class="text-box">
      <h3 class="font-light">
        WrapKit helps you to
        <span class="text-info">build your project</span> in record time with
        fun making it.
      </h3>
      <p>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
        nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
        volutpat.
      </p>
      <p>
        Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquis
        would be good to have.
      </p>

      <a
        class="btn btn-info-gradiant btn-md btn-arrow m-t-20"
        data-toggle="collapse"
        routerLink="#"
      >
        <span>View Other Features
          <i class="ti-arrow-right"></i>
        </span>
      </a>
    </div>
  </div>
  <!-- Column -->
</div>

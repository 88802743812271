<div class="card">
    <div class="card-header">
        <h3>Track the application status</h3>
    </div>
    <div mat-dialog-content style="padding:30px;">
        <form [formGroup]="userForm">
            <mat-form-field appearance="outline">
                <mat-label>Application Number</mat-label>
                <input matInput formControlName="application" placeholder="Provide your application number">
            </mat-form-field>

            <div mat-dialog-action [align]="'middle'">
                <button mat-raised-button color="primary" (click)="trackApplication()">Search</button>&nbsp;
                <button mat-raised-button color="warn" mat-dialog-close>Close</button>
            </div>
        </form>

        <div *ngIf="showdata">
            <ion-item>
                <ion-label>
                  <h2>Application Status:  {{appStatus}}</h2>
           
                  <p *ngIf="remarkShow">Remarks: {{data?.remarks}}</p>
                </ion-label>
              </ion-item>
               

        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { TokenStorageService } from '../../services/token-storage.service';
import { UtilityService } from '../../services/utility.service';
import { AuthService } from '../../services/auth.service';
import { AuthComponent } from '../../auth/auth.component';
import { environment } from '../../../environments/environment';

  @Component({
    selector: 'app-admin_nav',
    templateUrl: './admin_nav.component.html',
    styleUrls: ['./admin_nav.component.css']
  })
  
  export class AdminMenuComponent  implements OnInit {
    
    @ViewChild(MatPaginator) paginator!: MatPaginator; //Add ! if there is error as paginator!
    @ViewChild(MatSort) sort!: MatSort;
    isLoggedIn: boolean = false;
    showAdminBoard = false;
    showModeratorBoard = false;
    username?: string;
    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';
    file: File;
    imageError: string;
    isImageSaved: boolean;
    cardImageBase64: string;
    uploadedFiles: any;
    userPanelAccess: boolean = false;
    postPanelAccess: boolean = false;
    rolePanelAccess: boolean = false;
    permissionPanelAccess: boolean = false; 
  
    constructor(
        private tokenStorageService: TokenStorageService,
        private router: Router,
        private dialog: MatDialog,
        private utility: UtilityService,
        private authService: AuthService
      ) { 
       
      }
  
    ngOnInit(): void {
      if(this.tokenStorageService.getUser() !== undefined)
      {
        this.username = this.tokenStorageService.getUser().user_name;
        if(this.tokenStorageService.getUser().isLoggedIn == 1){
          this.isLoggedIn = true;
        }
  
        // console.log("cc="+this.isLoggedIn);
  
        if(this.tokenStorageService.getUser().userPanelAccess == 1){
          this.userPanelAccess = true;
        }
        if(this.tokenStorageService.getUser().rolePanelAccess == 1){
          this.rolePanelAccess = true;
        }
        if(this.tokenStorageService.getUser().permissionPanelAccess == 1){
          this.permissionPanelAccess = true;
        }
        if(this.tokenStorageService.getUser().postPanelAccess == 1){
          this.postPanelAccess = true;
        }
      
        if(this.tokenStorageService.getToken() === null){
          this.isLoggedIn = false;
          this.router.navigateByUrl('/');
        }else{
          this.router.navigateByUrl('/dashboard');
          this.isLoggedIn = true;
        }
      }
    }
  
    loginDialog() {
      this.dialog.open(AuthComponent, {
       width: '35%'
      }).afterClosed().subscribe(() => {
          this.reloadPage();
          //this.router.navigateByUrl('/');
        })
    }
          
    logout(): void {
      // this.tokenStorageService.signOut();
      this.authService.logout()
      .subscribe({
          next: () => {
            this.isLoggedIn = false;
            this.utility.successNotification("You have successfully logout.");
            this.tokenStorageService.signOut();
            this.reloadPage();
          }
      });
    }
  
    reloadPage(): void {
      window.location.href=environment.webUrl;
    }

    selectFile1(event: any): boolean {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg','application/pdf'];
  
      if (event.target.files[0].size > max_size) {
          this.imageError =
              'Maximum size allowed is ' + max_size / 1000 + 'Mb';
          return false;
      }
  
      if (!_.includes(allowed_types, event.target.files[0].type)) {
          this.imageError = 'Only type allowed are ( JPG | PNG | PDF)';
          return false;
      }
     this.selectedFiles = event.target.files;
    //  console.log(this.selectedFiles);
    //  console.log(this.selectedFiles.item(0));
    }
  
    handleUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
          // console.log(reader.result);
      };
  }
  
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  
  upload(): void {
    this.progress = 0;
  }
  
  }
  
import { Component } from '@angular/core';
import { ServiceblogService } from '../services/blog-service.service';
import { environment } from '../../environments/environment';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent {
  
  constructor(public service:ServiceblogService) {
  }

}

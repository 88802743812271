import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SmartcardRegistrationService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(environment.authApiURL + 'login', {
      email,
      password
    }, httpOptions);
  }


  trackApplication(appId): Observable<any> {
    return this.http.get<any>(environment.ApiURL + 'smartcard/trackApp/'+appId)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getRegistrationType(): Observable<any> {
    return this.http.get<any>(environment.ApiURL + 'smartcard/getRegType')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getCardType(): Observable<any> {
    return this.http.get<any>(environment.ApiURL + 'smartcard/getCardType')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getContact(): Observable<any> {
    return this.http.get<any>(environment.ApiURL + 'smartcard/contact')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  homeMessage(): Observable<any> {
    return this.http.get<any>(environment.ApiURL + 'smartcard/message')
    .pipe(
      catchError(this.errorHandler)
    )
  }
  
  logout(): Observable<any> {
    return this.http.post(environment.authApiURL + 'logout',
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  checkDuplication(cid, regType,): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('cid', cid);
    formData.append('regType', regType);
  return this.http.post<any>(environment.ApiURL + 'smartcard/checkrecord', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  register(id,name,mobile,email,gender,dob,location,cardType,regType,cid,status,remarks,uploadFile:File,author): Observable<any> {
    const formData: FormData = new FormData();
    if(status === undefined){
      status = 'Under Process';
    }
    if(remarks === null){
      remarks = '';
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('cid', cid);
    formData.append('mobile', mobile);
    formData.append('gender', gender);
    formData.append('dob', dob);
    formData.append('location', location);
    formData.append('cardType', cardType);
    formData.append('regType', regType);
    formData.append('uploadFile', uploadFile);
    formData.append('status', status);
    formData.append('remarks', remarks);
    formData.append('id', id);
    formData.append('author', author);
    

  return this.http.post<any>(environment.ApiURL + 'smartcard/register', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }
  
  errorHandler(error: any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  
  gender = ["Male","Female"];
  userForm : UntypedFormGroup;
  roles: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<DialogComponent>
  ) { 

    this.userForm = this.formBuilder.group({
        name:   ['phurpa22', [ Validators.required ]],
        email:  ['phu@aa.com', [Validators.required, Validators.email]],
        role_id:   ['1', Validators.required],
        password:   ['123123123', Validators.required],
        confirmPassword:   ['123123123', Validators.required]
    });

  }
  
  ngOnInit(): void {
    this.getRoles();
  }

  getRoles(){
    this.userService.getRole().subscribe((data)=>{
      this.roles = data;
      console.log(this.roles);
    });
  }

  addUser(){
    console.log(this.userForm.value);
    this.userService.create(this.userForm.value)
    .subscribe({
      next: (res)=>{
      alert("Added");
      this.userForm.reset();
      this.dialogRef.close('Save');
      },
      error:()=>{
        alert("Error to add");
      }
    })
  }

}

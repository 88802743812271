<div id="main-wrapper" *ngIf="!isLoggedIn">
    <app-banner></app-banner>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="blog-home">
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleDashboardComponent } from './role-dashboard/role-dashboard.component';

const routes: Routes = [
  { path: 'role', redirectTo: 'role/role-dashboard', pathMatch: 'full'},
  { path: 'role/role-dashboard', component: RoleDashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }

<div class="bg-light rounded">
    <div class="container">
        <h2>{{ actionBtn}} Role </h2>
    </div>
       
    <div>
        <div class="form-group">
            <label>Role Name</label>
            <input type="text" [(ngModel)]="role_name" class="form-control" required>
        </div>

        <div class="form-group">
            <label>Description</label>
            <input type="text" [(ngModel)]="short_code" class="form-control">
        </div>

        <div class="form-group">
            <label for="permissions">Permissions</label>
            <ng-select 
                [multiple]="true" 
                [items]="permissionsL"
                [closeOnSelect]="false"
                (change)="onSelectionChange($event)"   
                (blur)="onTouched()"    
                placeholder="Select permissions"
                [(ngModel)]="selectedPermissions"
                dropdownPosition = bottom
                [virtualScroll] = true
                bindLabel="name"
                bindValue="id"
                >

                <ng-template ng-header-tmp let-items="items">
                    <input type="checkbox" (change)="toggleCheckAll($event)"/> All
                </ng-template>

                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of items | slice:0:3">
                        <span class="ng-value-label">{{item.name}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                    <div class="ng-value" *ngIf="items.length > 3">
                        <span class="ng-value-label">{{items.length - 3}} more...</span>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" /> {{item.name}}
                </ng-template>
            
            </ng-select>
        </div>   

        <div mat-dialog-action [align]="'end'">
            <button mat-raised-button color="primary" (click)="addRole()">{{ actionBtn}}</button>
            <button style="margin-left:8px; margin-top:8px; margin-right: 10px;" mat-raised-button color="warn" mat-dialog-close>Close</button>
        </div>
    </div>
</div>


    
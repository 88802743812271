<div class="card">
    <div class="card-header">
        <h3>Online - Smart Card Registration Form</h3>
    </div>
    <div mat-dialog-content style="padding:35px;">
        <form [formGroup]="userForm">
            <!-- <mat-radio-group [(ngModel)]="regType" [ngModelOptions]="{standalone: true}" (change)="selectType()">
                <label>Registration Type</label><br>
                <mat-radio-button value="1" [checked]='true'>New&nbsp;</mat-radio-button>
                <mat-radio-button value="2">Renewal&nbsp;</mat-radio-button>
                <mat-radio-button value="3">Replacement&nbsp;</mat-radio-button>
            </mat-radio-group> -->

            <mat-form-field class="name" appearance="outline">
                <mat-label>Full Name</mat-label>
                <input matInput formControlName="name" placeholder="Your full anme" required>
                <mat-error *ngIf="userForm.get('name').hasError('required')">
                    Name is required*
                </mat-error>
            </mat-form-field>


            <div class="divider"></div>
            <mat-form-field appearance="outline">
                <mat-label>Gender</mat-label>
                <select formControlName="gender" matNativeControl required>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>CID/Student Card</mat-label>
                <input matInput formControlName="cid" (change)="checkCid($event)" placeholder="Your CID or Student card number">
                <mat-error *ngIf="userForm.get('cid').hasError('required')">
                    CID/Student Card is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Mobile</mat-label>
                <input matInput formControlName="mobile" placeholder="Your mobile number">
                <mat-error *ngIf="userForm.get('mobile').hasError('required')">
                    Contact number is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Email </mat-label>
                <input matInput formControlName="email" placeholder="Your email (Optional)">
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Data of Birth</mat-label>
                <input matInput formControlName="dob" readonly placeholder="Date of birth" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div class="divider"></div>


            <mat-form-field appearance="outline">
                <mat-label>Current Address </mat-label>
                <input matInput formControlName="location" placeholder="Your location address">
                <mat-error *ngIf="userForm.get('location').hasError('required')">
                    Current Location is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Registration Type </mat-label>
                <select formControlName="regType" (change)="check($event)" matNativeControl required>
                    <option *ngFor="let rtype of regType" [value]="rtype.id">
                    {{ rtype.regType }}
                    </option>
                </select>
            </mat-form-field>


            <div class="divider"></div>
            <mat-form-field appearance="outline">
                <mat-label>Card Type </mat-label>
                <select formControlName="cardType" matNativeControl required>
                    <option *ngFor="let ctype of cardType" [value]="ctype.id">
                    {{ ctype.cardType }}
                    </option>
                </select>
            </mat-form-field>

            <div class="divider"></div>
            <mat-form-field appearance="outline">
                <mat-label>Documents (CID or Student Card for concessional card. File size < 300kb)</mat-label>
                <mat-toolbar>
                    <!-- Display files names -->
                    <input matInput [(ngModel)]="fileAttr" [ngModelOptions]="{standalone: true}"  (change)="uploadFileEvt($event)" readonly name="uploadFile" required />
                    <!-- Browse Button -->
                    <button mat-flat-button color="primary">Browse File</button>
                </mat-toolbar>
                <!-- Fetch selected filed on change -->
                <input type="file" id="uploadFile" name="uploadFile" class="form-control" (change)="uploadFileEvt($event)" required>
            </mat-form-field>

            <div class="divider"></div>
            <mat-checkbox formControlName="tor" required>I have read the instructions given in the home page.
            </mat-checkbox>

            <div class="divider"></div>
            <div mat-dialog-action [align]="'middle'">
                <button mat-raised-button color="primary" [disabled]="!userForm.valid" (click)="submit()">Submit</button>&nbsp;
                <button mat-raised-button color="warn" mat-dialog-close>Close</button>
            </div>

        </form>
    </div>
</div>
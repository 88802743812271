import { RegisterComponent } from './register/register.component';
import { ContactComponent } from './contact/contact.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { FullComponent } from './layout/full/full.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { TrackComponent } from './track/track.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'track', component: TrackComponent },
      { path: 'auth/', component: AuthComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { UtilityService } from '../services/utility.service';
import { Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { EncrDecrService } from '../services/EncrDecrService';
import { CheckRoleService } from '../services/checkRole.services';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
  
  export class AuthComponent implements OnInit {
    
    userForm : UntypedFormGroup;
    isLoggedIn = false;
    checkSum: string;

    constructor(
      private formBuilder: UntypedFormBuilder,
      public  utilityService: UtilityService,
      private authService: AuthService,
      private checkRoleService: CheckRoleService,
      private encrDecr: EncrDecrService,
      private spinner: NgxSpinnerService,
      private tokenStorage: TokenStorageService    ) { 
      this.userForm = this.formBuilder.group({
          email:  ['', [Validators.required, Validators.email]],
          password:   ['', Validators.required],
      });
    }
    
    ngOnInit(): void {
    }
  
    authLogin(){
      this.spinner.show();
      this.authService.login(this.userForm.value.email,this.userForm.value.password)
      .subscribe({
          next: (res)=>{
          //this.utilityService.successNotification("Login success");
         
          //this.router.navigateByUrl('/dashboard');
          // this.userForm.reset();
          // this.dialogRef.close('Save');

          // console.log(res);
          this.checkSum = this.checkRoleService.setUserAccessPermission(res);
          var encryptData = this.encrDecr.set('3738^%$!#@^@1ERF', this.checkSum);
       
          // var decryptedData = this.encrDecr.get('3738^%$!#@^@1ERF', encryptData);
          // console.log("\nEncryptedData ="+encryptData);
          // console.log('\ndecryptedData :' + decryptedData);
        
          this.tokenStorage.saveToken(res.access_token);
          this.tokenStorage.saveUserData(encryptData);
          this.reloadPage();
          this.isLoggedIn = true;
          this.spinner.hide();
        },
        error:()=>{
          this.spinner.hide();
          this.isLoggedIn = false;
          this.utilityService.errorNotification("Invalid credentials.Try again!");
        }
      })
    }
  
    reloadPage(): void {
      window.location.href=environment.webUrl;
    }
  }
  
<div class="card">
    <div class="card-header">
        <h3>Provide valid credential to access the system...</h3>
    </div>
    <div mat-dialog-content style="padding:30px;">
        <form [formGroup]="userForm">
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput formControlName="email" placeholder="Enter username">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" placeholder="Password">
            </mat-form-field>

            <div mat-dialog-action [align]="'middle'">
                <button mat-raised-button color="primary" (click)="authLogin()">Login</button>&nbsp;
                <button mat-raised-button color="warn" mat-dialog-close>Close</button>
            </div>
        </form>
    </div>
</div>
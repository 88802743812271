<div class="container" *ngIf="userLoggedIn">
    <div class="bg-light p-1 rounded">
        <div class="card">
            <div class="card-header">Dashboard - User List 
                <button type="button" class="btn btn-primary btn-sm float-end" (click)="addUserDialog()" *ngIf="canCreateUser"> Add User</button>
            </div>

            <div class="card-body">
                <div class="table-responsive-sm shadow-lg bg-white rounded">
                        <div class="search">
                            <mat-form-field appearance="outline" [style.width.%]="20">
                                <mat-label>Search</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Type name to search" #input>
                            </mat-form-field>
                        </div>
    
                    <table mat-table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> No.</th>
                            <td mat-cell *matCellDef="let item; let i = index">
                                {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                            </td>
                        </ng-container>
                
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>
                
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                            <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="role_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
                            <td mat-cell *matCellDef="let row"> {{row.role_id.role_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef> Created Date </th>
                            <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let row"> 
                                <button type="button" (click)="editUser(row)" class="btn btn-primary btn-sm" *ngIf="canEditUser">Edit</button>
                                <button type="button" style="margin-left:8px;" (click)="deleteUser(content, row.id)" class="btn btn-danger btn-sm" *ngIf="canDeleteUser">Delete</button>
                            </td>
                        </ng-container>
                            
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                    </table>
    
                    <mat-paginator
                        fxFlex="100"
                        #paginatorRef
                        [length]="10"
                        [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                    
                </div>
            </div>
    
        </div>
    </div>
</div>

<!-- spinner -->
<div *ngIf="showLoader">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-spin-clockwise">  
        <p style="color: white">Please Wait. </p>  
    </ngx-spinner>  
</div> 

<!-- Delete Modal -->
<app-delete-confirmation-dailog></app-delete-confirmation-dailog>
  <ng-template #content let-c="close" let-d="dismiss">    
      <div class="modal-header">    
          <h3 class="modal-title">Please confirm...</h3>    
          <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">    
          </button>   
      </div>   
      
      <div class="modal-body">    
          <p>Are you sure to delete this, once deleted cannot be recovered?</p>    
      </div>    
      <div class="modal-footer">    
          <button type="button" class="btn btn-outline-warning" (click)="d('Cross click')">Cancel</button>    
          <button type="button" class="btn btn-outline-danger" (click)="c('yes')">Yes</button>    
      </div>  
  </ng-template> 
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CheckRoleService {
    checkSum: string;

    setUserAccessPermission(res){
        this.checkSum = res.user['id']+'|'+res.user['name']+'|'+res.user['email']+'|'
        +res.user['role_id']+'|'
        +res.panel_access['userPanelAccess']+'|'
        +res.panel_access['rolePanelAccess']+'|'
        +res.panel_access['permissionPanelAccess']+'|'
        +res.panel_access['postPanelAccess']+'|'
        +'1';
        return this.checkSum;
    }
}
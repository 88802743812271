<div class="spacer feature22">
    <div class="card container">
        <h2>Contact us at the following details</h2>

        <p>
            <mat-icon>contact_phone</mat-icon> {{contactdtl?.phone}} </p>
        <p>
            <mat-icon>public</mat-icon> {{contactdtl?.website}}</p>
            <mat-icon>map</mat-icon> <div [innerHTML]="contactdtl?.address"></div>
    </div>
    <div mat-dialog-action [align]="'middle'">
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
    </div>
</div>
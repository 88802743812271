import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})
export class FullComponent implements OnInit {

 
  isLoggedIn: boolean = false;

  constructor( private router: Router,private tokenStorageService: TokenStorageService)
  {

  }

  ngOnInit(): void {
    if(this.tokenStorageService.getUser() === undefined){
      this.isLoggedIn = false;
    }else{
      this.isLoggedIn = true;
    }
  }

  showModal: boolean;
  show()
  {
    this.showModal = true; // Show-Hide Modal Check
    
  }
  //Bootstrap Modal Close event
  hide()
  {
    this.showModal = false;
  }

}

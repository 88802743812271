
<h1>Add User</h1>
<div mat-dialog-content>
    <form [formGroup]="userForm" action="">
            <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" placeholder="Placeholder">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="Placeholder">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" placeholder="Placeholder">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="confirmPassword" placeholder="Placeholder">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role_id">
                        <mat-option *ngFor="let r of roles" [value]="r.id">
                            {{ r.role_name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                    
                <mat-form-field appearance="outline">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            
                <mat-label>Gender</mat-label>
                <mat-radio-group class="example-radio-group">
                    <mat-radio-button class="example-radio-button" *ngFor="let g of gender" [value]="g">
                        {{ g}}        
                    </mat-radio-button>
                </mat-radio-group>
            
                <mat-form-field appearance="outline">
                    <mat-label>Price</mat-label>
                    <input matInput type="number" placeholder="Placeholder">
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Comment</mat-label>
                    <textarea matInput></textarea>
                </mat-form-field>
            
                <div mat-dialog-action [align]="'end'">
                    <button mat-raised-button color="primary" (click)="addUser()">Save</button>
                    <button style="margin-left:5px;" mat-raised-button color="warn" mat-dialog-close>Close</button>
                </div>
    </form>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utility.service';
import { CustomValidators } from '../../providers/CustomValidators';

@Component({
  selector: 'app-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.css']
})
export class UserIndexComponent implements OnInit {
  
  userForm : UntypedFormGroup;
  roles: any;
  actionBtn: string = 'Add';
  submitted = false;
  
  constructor(
    private userService: UserService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private dialogRef: MatDialogRef<UserIndexComponent>
  ) {   }
  
  ngOnInit(): void {
    this.getRoles();

    if(this.editData){
      this.userForm = new UntypedFormGroup(
        {
          name: new UntypedFormControl('', [Validators.required]),
          email: new UntypedFormControl('', [Validators.required, Validators.email]),
          role_id: new UntypedFormControl('', [Validators.required]),
          password: new UntypedFormControl('', [Validators.minLength(6)]),
          confirmPassword: new UntypedFormControl('')
        },
        CustomValidators.mustMatch('password', 'confirmPassword')
      );
    
      this.userForm.controls["name"].setValue(this.editData.name);
      this.userForm.controls["email"].setValue(this.editData.email);
      this.userForm.controls["role_id"].setValue(this.editData.role_id);
      this.actionBtn = "Update";
    }else{
      this.userForm = new UntypedFormGroup(
        {
          name: new UntypedFormControl('', [Validators.required]),
          email: new UntypedFormControl('', [Validators.required, Validators.email]),
          role_id: new UntypedFormControl('', [Validators.required]),
          password: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(6)
          ]),
          confirmPassword: new UntypedFormControl('', [Validators.required])
        },
        CustomValidators.mustMatch('password', 'confirmPassword')
      );
    }
  }

  get f() {
    return this.userForm.controls;
  }

  getRoles(){
    this.userService.getRole().subscribe((data)=>{
      this.roles = data;
    });
  }

  addUser(){
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }

    if(!this.editData){
      if(this.userForm.valid){
        this.userService.create(this.userForm.value)
        .subscribe({
          next: () => {
            this.utilityService.successNotification("Successfully added");
            this.userForm.reset();
            this.dialogRef.close('Save');
          },
          error:()=>{
            this.utilityService.errorNotification("Error: Make sure same username or email donot exist.");
          }
        })
      }
    }else{
      this.updateUser(this.editData.id, this.userForm.value);
    }
  }

  updateUser(id,data)
  {
    this.userService.update(id, data)
    .subscribe({
      next: () => {
        this.utilityService.successNotification("Successfully updated");
        this.userForm.reset();
        this.dialogRef.close('update');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in updating the user");
      }
    })

  }

  deleteUser(id){
    this.userService.delete(id)
    .subscribe({
      next: () => {
        this.utilityService.successNotification("Successfully deleted");
        this.userForm.reset();
        this.dialogRef.close('Save');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in deleting the user");
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(environment.authApiURL + 'login', {
      email,
      password
    }, httpOptions);
  }

  
  logout(): Observable<any> {
    return this.http.post(environment.authApiURL + 'logout',
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  register(id,name,mobile,email,gender,dob,location,cardType,regType,cid,status,remarks,cardnumber,uploadFile:File,user_id): Observable<any> {
    const formData: FormData = new FormData();
    if(status === undefined){
      status = '';
    }
    if(remarks === null){
      remarks = '';
    }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('cid', cid);
    formData.append('mobile', mobile);
    formData.append('gender', gender);
    formData.append('dob', dob);
    formData.append('location', location);
    formData.append('cardType', cardType);
    formData.append('regType', regType);
    formData.append('uploadFile', uploadFile);
    formData.append('status', status);
    formData.append('remarks', remarks);
    formData.append('id', id);
    formData.append('author', user_id);
    formData.append('cardnumber', cardnumber);

  return this.http.post<any>(environment.ApiURL + 'smartcard/register', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }
  
  errorHandler(error: any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}

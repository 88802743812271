import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from '../../services/utility.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { RoleService } from '../../services/role.service';
import { RoleIndexComponent } from '../role-index/role-index.component';
import { tap } from 'rxjs/operators';
import { CheckRoleService } from '../../services/checkRole.services';
import { EncrDecrService } from '../../services/EncrDecrService';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-role-dashboard',
  templateUrl: './role-dashboard.component.html',
  styleUrls: ['./role-dashboard.component.css']
})
export class RoleDashboardComponent implements OnInit {

  displayedColumns: string[] = ['id', 'role_name', 'short_code', 'created_at','action'];
  dataSource: MatTableDataSource<any>;
  showLoader: boolean;
  checkSum: string;

  canCreateRole: boolean = false;
  canEditRole: boolean = false;
  canDeleteRole: boolean = false;
  userLoggedIn: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private dialog: MatDialog,
    private tokenStorage: TokenStorageService, 
    private encrDecr: EncrDecrService,
    private checkRoleService: CheckRoleService,
    private modalService: NgbModal,
    private utilityService: UtilityService,
    private roleService: RoleService,
    private SpinnerService: NgxSpinnerService
  ) {   }
  
  ngOnInit(): void {
    this.getAllRoles(true);
  }

  ngAfterViewInit() {
    if(this.userLoggedIn){
      this.paginator.page
      .pipe(
          tap(() => this.getAllRoles(true))
      )
      .subscribe();
    }
  }


  editRole(row: any){
    this.dialog.open(RoleIndexComponent,{
      width: '60%',
      height: '60%',
      data: row
    }).afterClosed().subscribe(val=>{
      if(val === 'update'){
        this.getAllRoles(false);
      }
    });
  }

  deleteRole(content: any, id: number)
  {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
        if (result === 'yes') {  
          this.deleteRoleRecord(id);  
        }  
      }, (reason) => {  
      });  
  }

  deleteRoleRecord(id){
    this.roleService.delete(id)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully deleted the role");
        this.getAllRoles(false);
      },
      error : ()=>{
        this.utilityService.errorNotification("Error in deleting the role");
      }
    })
  }
  
  addRoleDialog() {
    this.dialog.open(RoleIndexComponent, {
      width: '60%',
      height: '60%'
    }).afterClosed().subscribe(val=>{
      this.getAllRoles(false);
    })
  }

  getAllRoles(displayLoader){
    if(displayLoader){
      this.showLoader = true;
    }else{
      this.showLoader = false;
    }

    this.SpinnerService.show(); 

    if(this.tokenStorageService.getToken() === null){
      this.redirectPage();
    }else{
      this.userLoggedIn = true;
    }
  
    this.roleService.getAllRoles()
    .subscribe({
      next: (res)=>{
      
        this.SpinnerService.hide(); 
        this.dataSource = new MatTableDataSource(res['roles']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.checkSum = this.checkRoleService.setUserAccessPermission(res);
        var encryptData = this.encrDecr.set('3738^%$!#@^@1ERF', this.checkSum);
        this.tokenStorage.saveUserData(encryptData);


        if(res['userPermission']['createRole'] == 1){
          this.canCreateRole = true;
        }
        if(res['userPermission']['editRole'] == 1){
          this.canEditRole = true;
        }
        if(res['userPermission']['deleteRole'] == 1){
          this.canDeleteRole = true;
        }
      },
      error: (err)=>{
        console.log("Error ....")
      }
    })
  }
    
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  redirectPage(): void {
    this.tokenStorageService.signOut();
    window.location.href=environment.webUrl;
  }

}

<!-- <div
  class="banner-innerpage"
  style="background-image: url(assets/images/landingpage/banner-bg2.jpg)"
>
</div> -->

<div class="spacer feature22">
  <div class="card container">
    <h2>Contact us at the following details</h2>
    <p> Mobile: 1759-2865 </p> 
    <p> Email : webdesignerw@gmail.com </p> 
    <p> Address : Thimphu </p> 
  </div>
  <div mat-dialog-action [align]="'middle'">
      <button mat-raised-button color="warn" mat-dialog-close>Close</button>
  </div>
</div>
import { PostService } from './../../services/post.service';
import { DataService } from './../../services/data.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as CanvasJS from '../../../assets/canvasjs/canvasjs.min';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UtilityService } from '../../services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { SmartcardRegistrationService } from '../../services/smartcardregistration.service';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.css'],
  providers: [DatePipe]
})
export class DashboardDetailsComponent implements OnInit {
  username: string;
  userLoggedIn: boolean = false;
  showLoader: boolean;
  applications: any;
  submitted = false;

  displayedColumns: string[] = ['id', 'name','cid','mobile', 'dob','regType','cardType','status','file','created_at'];
  dataSource: MatTableDataSource<any>;
  
 filePath: any;

  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
  id: any;
  blogDetail: any;
  postAttachment: any;
  actionBtn= '';
  updateBtn: boolean = false;
  updateBtnEnabled: boolean = false;
  showStatusChecked: boolean = false;
  showCardNumberText: boolean = false;
  status:any;
  
  postForm = new UntypedFormGroup(
    {
      name: new UntypedFormControl('', [Validators.required]),
      dob: new UntypedFormControl('', [Validators.required]),
      mobile: new UntypedFormControl('', [Validators.required]),
      cid: new UntypedFormControl('', [Validators.required]),
      cardType: new UntypedFormControl('', [Validators.required]),
      regType: new UntypedFormControl('', [Validators.required]),
      location: new UntypedFormControl('', [Validators.required]),
      gender: new UntypedFormControl('', [Validators.required]),
      created_at: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl(''),
      status:new UntypedFormControl(''),
      remarks:new UntypedFormControl(''),
      author: new UntypedFormControl(''),
      id:new UntypedFormControl(''),
      cardnumber:new UntypedFormControl('')
    });
  selectedPostImage: any;
  regType: any;
  cardType: any;
 
  
  constructor( 
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private postService: PostService,
    public  utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private dataService: DataService,
    activatedRouter: ActivatedRoute,
    private smartcardRegistrationService: SmartcardRegistrationService,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<DashboardDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {

    this.id = activatedRouter.snapshot.paramMap.get('id');

   }

  ngOnInit(): void {
    // console.log(this.editData);
    this.username = this.tokenStorageService.getUser().user_id;

    if(this.editData){
      this.postForm.controls["id"].setValue(this.editData.id);
      this.postForm.controls["name"].setValue(this.editData.name);
      this.postForm.controls["cardType"].setValue(this.editData.cardType);
      this.postForm.controls["regType"].setValue(this.editData.regType);
      this.postForm.controls["email"].setValue(this.editData.email);
      this.postForm.controls["location"].setValue(this.editData.location);
      this.postForm.controls["gender"].setValue(this.editData.gender);
      this.postForm.controls["cid"].setValue(this.editData.cid);
      this.postForm.controls["status"].setValue(this.editData.status);
      this.postForm.controls["remarks"].setValue(this.editData.remarks);
      this.postForm.controls["dob"].setValue(this.datePipe.transform(this.editData.dob, 'yyyy-MM-dd'));
      this.postForm.controls["created_at"].setValue(this.datePipe.transform(this.editData.created_at, 'yyyy-MM-dd'));
      this.postForm.controls["mobile"].setValue(this.editData.mobile);
      this.postForm.controls["cardnumber"].setValue(this.editData.cardnumber);
      this.postForm.controls["author"].setValue(this.username);
      
      this.actionBtn = "Update";

      if(this.editData.action == 'Under Process'){
        this.showStatusChecked = true;
        this.updateBtnEnabled = true;
        this.showCardNumberText = false;
      }
      if(this.editData.action == 'Approved'){
        this.showStatusChecked = false;
        this.updateBtnEnabled = true;
        this.showCardNumberText = true;
        this.postForm.controls["status"].setValue("Approved");
      }
    }
    this.getRegistrationType();
    this.getCardType();
  }
  
  get f() {
    return this.postForm.controls;
  }

getRegistrationType(){
  this.smartcardRegistrationService.getRegistrationType()
  .subscribe({
      next: (res)=>{
        this.regType = res;
      }
    });
}


getCardType(){
  this.smartcardRegistrationService.getCardType()
  .subscribe({
      next: (res)=>{
        this.cardType = res;
      }
    });
}

  selectFile(event: any) {
    this.selectedPostImage = event.target.files;
  }

  updateApplications(){
     
    if(this.postForm.value.status === undefined){
      this.postForm.value.status = "Approved";
    }

    this.authService.register(
      this.postForm.value.id,
      this.postForm.value.name,
      this.postForm.value.mobile,
      this.postForm.value.email,
      this.postForm.value.gender,
      this.postForm.value.dob,
      this.postForm.value.location,
      this.postForm.value.cardType,
      this.postForm.value.regType,
      this.postForm.value.cid,
      this.postForm.value.status,
      this.postForm.value.remarks,
      this.postForm.value.cardnumber,
      null,
      this.username
    )
    .subscribe({
        next: (res)=>{
        this.spinner.hide();

        this.utilityService.successNotification("Successfully "+ this.postForm.value.status+" the application");
        this.postForm.reset();
        this.dialogRef.close('update');
      },
      error:()=>{
        this.spinner.hide();
        this.utilityService.errorNotification("Failed to update.Please try again!");
      }
    })

  }

}

<div class="card">
    <div class="card-header">
        <h3>{{ actionBtn}} Application </h3>
    </div>
    <div mat-dialog-content style="padding:35px;">
        <form [formGroup]="postForm">

            <mat-form-field class="name" appearance="outline">
                <mat-label>Full Name</mat-label>
                <input matInput formControlName="name" placeholder="Your full anme" required>
                <mat-error *ngIf="postForm.get('name').hasError('required')">
                    Name is required*
                </mat-error>
            </mat-form-field>


            <div class="divider"></div>
            <mat-form-field appearance="outline">
                <mat-label>Gender</mat-label>
                <select formControlName="gender" matNativeControl required>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>CID/Student Card</mat-label>
                <input matInput formControlName="cid" placeholder="Your CID or Student card number">
                <mat-error *ngIf="postForm.get('cid').hasError('required')">
                    CID/Student Card is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Mobile</mat-label>
                <input matInput formControlName="mobile" placeholder="Your mobile number">
                <mat-error *ngIf="postForm.get('mobile').hasError('required')">
                    Contact number is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Email </mat-label>
                <input matInput formControlName="email" placeholder="Your email (Optional)">
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Data of Birth</mat-label>
                <input matInput formControlName="dob" readonly placeholder="Date of birth" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div class="divider"></div>


            <mat-form-field appearance="outline">
                <mat-label>Location of resident </mat-label>
                <input matInput formControlName="location" placeholder="Your location address">
                <mat-error *ngIf="postForm.get('location').hasError('required')">
                    Current Location is required*
                </mat-error>
            </mat-form-field>

            <div class="divider"></div>

            <mat-form-field appearance="outline">
                <mat-label>Registration Type </mat-label>
                <select formControlName="regType" matNativeControl required>
                    <option *ngFor="let rtype of regType" [value]="rtype.id">
                    {{ rtype.regType }}
                    </option>
                </select>
            </mat-form-field>


            <div class="divider"></div>
            <mat-form-field appearance="outline">
                <mat-label>Card Type </mat-label>
                <select formControlName="cardType" matNativeControl required>
                    <option *ngFor="let ctype of cardType" [value]="ctype.id">
                    {{ ctype.cardType }}
                    </option>
                </select>
            </mat-form-field>

            <div class="form-group">
                <label>Application Date</label>
                <input type="text" formControlName="created_at" class="form-control">
            </div>

            <div class="divider"></div>
            <div class="form-group" *ngIf="showCardNumberText">
                <label>Application Status</label>&nbsp;
                <mat-radio-group formControlName="status">
                    <mat-radio-button value="Approved" [checked]='true'>Approve</mat-radio-button>
                </mat-radio-group>
            </div>


            <div class="divider"></div>
            <div class="form-group">
                <label>Remarks</label>
                <textarea formControlName="remarks" class="form-control"></textarea>
            </div>

            <div class="divider"></div>
            <div class="form-group" *ngIf="showCardNumberText">
            <mat-form-field appearance="outline">
                <mat-label>Card Number</mat-label>
                <input matInput formControlName="cardnumber" placeholder="Enter Card Number">
            </mat-form-field>
            </div>

            <div class="divider"></div>
            <div class="form-group" *ngIf="showStatusChecked">
                Please take the action:
                <mat-radio-group formControlName="status">
                    <mat-radio-button value="Approved" [checked]='true'>Approve</mat-radio-button>
                    <mat-radio-button value="Rejected">Reject</mat-radio-button>
                </mat-radio-group>
            </div>

            <div mat-dialog-action [align]="'end'">
                <button mat-raised-button color="primary" [disabled]="!postForm.valid" *ngIf="updateBtnEnabled" (click)="updateApplications()">{{ actionBtn}}</button>&nbsp;
                <button mat-raised-button color="warn" mat-dialog-close>Close</button>
            </div>
        </form>
    </div>
</div>
import { ApprovedComponent } from './../approved/approved.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard-index/dashboard.component';

const routes: Routes = [
  { path: 'dashboard', redirectTo: 'dashboard/dashboard-index', pathMatch: 'full'},
  { path: 'dashboard/dashboard-index', component: DashboardComponent },
  { path: 'approved', component: ApprovedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }


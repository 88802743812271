import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
 }

 constructor(private httpClient: HttpClient) { }
 
 getAllCatalogForPublic(id): Observable<any> {
  return this.httpClient.get<any>(environment.ApiURL+'catalog/public/'+id)
  .pipe(
    catchError(this.errorHandler)
  )
}


getRelayOn(): Observable<any> {
  return this.httpClient.get<any>(environment.ApiURL+'catalog/getRelayOn')
  .pipe(
    catchError(this.errorHandler)
  )
}

 getAllCatalog(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'catalog')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getCataloguesForUser(author): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.ApiURL+'catalog/getCataloguesForUser/'+author)//, JSON.stringify(author), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  getMasterStatus(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'catalog/getAllStatus')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getMasterCategory(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'catalog/getAllCategory')
    .pipe(
      catchError(this.errorHandler)
    )
  }
  
  create(data): Observable<any> {
    return this.httpClient.post<any>(environment.ApiURL+'catalog/', JSON.stringify(data), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  find(id: number): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL + 'catalog/'+id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

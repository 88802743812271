import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UtilityService } from '../services/utility.service';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SmartcardRegistrationService } from '../services/smartcardregistration.service';
import { TokenStorageService } from '../services/token-storage.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  userForm : UntypedFormGroup;
  checkSum: string;
  isLoggedIn: boolean;
  author: any;

  currentPostImage?: File;
  imageFile:  File;
  selectedPostImage?: FileList;
  
  fileAttr = 'Choose File (Size < 300kb)';
  @ViewChild('fileInput') fileInput: ElementRef;
  regType: any;
  cardType: any;

  constructor( 
    public  utilityService: UtilityService,
    private spinner: NgxSpinnerService,
   
    private smartcardRegistrationService: SmartcardRegistrationService,
    ) { }

  ngOnInit(): void {

    this.userForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl(''),
        mobile: new UntypedFormControl('', [Validators.required]),
        gender: new UntypedFormControl('', [Validators.required]),
        dob: new UntypedFormControl('', [Validators.required]),
        location: new UntypedFormControl('', [Validators.required]),
        cardType: new UntypedFormControl('', [Validators.required]),
        regType: new UntypedFormControl('', [Validators.required]),
        tor: new UntypedFormControl('', [Validators.required]),
        cid: new UntypedFormControl('', [Validators.required])
      }
    );
    this.getRegistrationType();
    this.getCardType();
 
  }
  
  selectType()
  {
   // alert(this.regType);
  }

  getRegistrationType(){
    this.smartcardRegistrationService.getRegistrationType()
    .subscribe({
        next: (res)=>{
          console.log(res);
          this.regType = res;
        }
      });
  }

  getCardType(){
    this.smartcardRegistrationService.getCardType()
    .subscribe({
        next: (res)=>{
          console.log(res);

          this.cardType = res;
        }
      });
  }

  uploadFileEvt(event: any){
   
    // console.log(event.target.files[0].size);
    if (event.target.files && event.target.files[0] && event.target.files[0].size/1024000 < 0.310) {
      this.fileAttr = '';
      Array.from(event.target.files).forEach((file: any) => {
        this.fileAttr += file.name;
      });
      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          let imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(event.target.files[0]);
      // Reset if duplicate image uploaded again
     // this.fileInput.nativeElement.value = '';
    } else {
      this.fileAttr = 'Upload File';
      this.utilityService.errorNotification("File should not be more than 300kb")
    }
    this.selectedPostImage = event.target.files;
  }

  checkCid(e){
    this.smartcardRegistrationService.checkDuplication( 
      this.userForm.value.cid,
      1
      )
      .subscribe({
      next: (res)=>{
        // console.log(res);
        if(res.length ==0){
        }else{
          //New Registration Type 
          if(res[0].regType == 1){
            this.utilityService.successConfirmation("Registration for "+this.userForm.value.cid+" is already submitted","warning");
          }
        }
      }
    })
  }

  check(event){
  this.smartcardRegistrationService.checkDuplication( 
    this.userForm.value.cid,
    this.userForm.value.regType
    )
    .subscribe({
    next: (res)=>{
      // console.log(res);
      if(res.length ==0){
      }else{
        //New Registration Type 
        if(res[0].regType == 1){
          this.utilityService.successConfirmation("Registration for "+this.userForm.value.cid+" is already submitted","warning");
        }
      }
    }
  })
}

  submit(){
    this.spinner.show();
    if(this.userForm.value.tor === ""){
      this.spinner.hide();
      this.utilityService.errorNotification("Please read Terms of use and check it.");
      return false;
    }

    if(this.selectedPostImage === undefined){
      this.spinner.hide();
      this.utilityService.errorNotification("Please upload the supporting document.");
      return false;
    }

    if (this.selectedPostImage) {
      this.imageFile = this.selectedPostImage.item(0);
      if (this.imageFile && this.imageFile.size/1024000 < 0.310){
        this.currentPostImage = this.imageFile;
      }else{
        this.spinner.hide();
        this.utilityService.errorNotification("File should be less than 300kb");
        return false;
      }
    }

    this.smartcardRegistrationService.register(
      '',
      this.userForm.value.name,
      this.userForm.value.mobile,
      this.userForm.value.email,
      this.userForm.value.gender,
      this.userForm.value.dob,
      this.userForm.value.location,
      this.userForm.value.cardType,
      this.userForm.value.regType,
      this.userForm.value.cid,
      '',
      '',
      this.currentPostImage,
      ''
    )
    .subscribe({
        next: (res)=>{
        this.utilityService.successConfirmation("Your application number is "+res['reg_num']+ ". Note this number to track the application status.","success");
        this.spinner.hide();
        this.isLoggedIn = false;
      },
      error:()=>{
        this.spinner.hide();
        this.utilityService.errorNotification("Failed to submit.Please try again!");
      }
    })

  }

  reloadPage(): void {
    window.location.href=environment.webUrl;
  }
}

import { ContactComponent } from './../../contact/contact.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../../auth/auth.component';
import { environment } from '../../../environments/environment';
import { RegisterComponent } from '../../register/register.component';
import { UtilityService } from '../../services/utility.service';
import { AuthService } from '../../services/auth.service';
import { AboutComponent } from '../../about/about.component';
import { TrackComponent } from '../../track/track.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  isLoggedIn: boolean = false;
  isCollapsed: boolean = false;
  showLogin : boolean = true;
  showPost : boolean = false;
  showRegister : boolean = true;
  
  url: string;
  subscriberUser: boolean = false;

  constructor( private router: Router,private tokenStorageService: TokenStorageService,
    private utility: UtilityService,
    private authService: AuthService,
    private dialog: MatDialog) { 
  }

  ngOnInit(): void {
    if(this.tokenStorageService.getUser() === undefined){
      this.isLoggedIn = false;
    }else{
      if(this.tokenStorageService.getUser().user_role === '3'){
        this.isLoggedIn = false;
        this.showLogin =  false;
        this.showRegister = false;
      }else{
        this.isLoggedIn = true;
      }
    }

    this.url = environment.webUrl;
  }
  
  track(){
    this.dialog.open(TrackComponent, {
      width: environment.width
      }).afterClosed().subscribe(() => {
          this.router.navigateByUrl('/');
      })
  }


  loginDialog() {
    this.dialog.open(AuthComponent, {
     width: '50%'
    }).afterClosed().subscribe(() => {
        this.showRegister = false;
        this.router.navigateByUrl('/');
    })
  }

  contactUs(){
    this.dialog.open(ContactComponent,{
      width: environment.width
    })
  }

  register(){
    this.dialog.open(RegisterComponent, {
      width: environment.width
     }).afterClosed().subscribe(() => {
         this.router.navigateByUrl('/');
     })
  }

         
  logout(): void {
    // this.tokenStorageService.signOut();
    this.authService.logout()
    .subscribe({
        next: (res) => {
          
          this.showRegister = true;
          this.isLoggedIn = false;
          this.utility.successNotification("You have successfully logout.");
          this.tokenStorageService.signOut();
          this.reloadPage();
        }
    });
  }

  reloadPage(): void {
    window.location.href=environment.webUrl;
  }

}

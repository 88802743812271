<div class="row m-t-10">
    <div class="col-md-4 wrap-feature1-box" *ngFor='let relay of relayOn'>
        <div class="shadow p-3 mb-5 bg-white rounded" data-aos="fade-right" data-aos-duration="1200">
            <div class="card-body p-30 text-center">
                <div class="icon-space display-5">
                    <i [class]="relay.icon"></i>
                </div>
                <h5 class="font-medium">{{relay.field}}</h5>
                <p class="m-t-20">
                    {{relay.fieldText}}
                </p>
                <a
                class="btn btn-info-gradiant btn-md btn-arrow m-t-20"
                data-toggle="collapse"
                href="#"
                ><span>View Details <i class="ti-arrow-right"></i></span
              ></a>
            </div>
        </div>
    </div>
</div>
<div class="banner-innerpage1" style="background-image: url(assets/images/landingpage/banner-bg2.jpg)">
</div>

<!-- RelayOn section -->
<div class="container">
    <div class="row mt-2">
        <div class="col-md-12 mt-3">
            <div class="shadow bg-white rounded" data-aos-duration="1200">
                <div class="card-body">
                    <div [innerHTML]="instructions?.details"></div>
                </div>
            </div>
        </div>
    </div>
</div>
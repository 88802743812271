import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from '../model/user';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Role } from '../model/role';
import { Permission } from '../model/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  items: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
 }

 constructor(private httpClient: HttpClient) { }

 getAllPermissions(): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(environment.ApiURL+'permissions')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  findRole(id): Observable<Permission> {
    return this.httpClient.get<Permission>(environment.ApiURL +'permissions/' + id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(data): Observable<Permission> {
    return this.httpClient.post<Permission>(environment.ApiURL+'permissions', JSON.stringify(data), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  update(id,data): Observable<Permission> {
    return this.httpClient.put<Permission>(environment.ApiURL +'permissions/'+id, JSON.stringify(data), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id){
    return this.httpClient.delete<Permission>(environment.ApiURL + 'permissions/' + id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }


  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

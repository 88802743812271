<div class="container-fluid" *ngIf="userLoggedIn">
    <div class="bg-light p-1 rounded">
        <div class="card">
            <div class="card-header"> Approved Application List
                <button [align]="float-end" class="btn btn-info btn-sm" (click)="exportAsXLSX()">Download Excel</button>
                <button [align]="float-end" class="btn btn-success btn-sm" (click)="openPDF()">Download PDF</button>
            </div>

            <div class="card-body">
                <div class="table-responsive-sm shadow-lg bg-white rounded">
                    <div class="search1">
                        <form [formGroup]="approvedForm">
                            <mat-form-field appearance="outline"  [style.width.%]="20">
                                <mat-label> From Date</mat-label>
                                <input matInput formControlName="fromDate" readonly placeholder="From Date" [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline"  [style.width.%]="20">
                                    <mat-label> To Date</mat-label>
                                    <input matInput formControlName="toDate" readonly placeholder="To Date" [matDatepicker]="picker1">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <button mat-raised-button color="primary" (click)="submit()">Submit</button>
                        </form>
                    </div>
                    
                    <div class="search">
                        <mat-form-field appearance="outline" [style.width.%]="20">
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Type title to search" #input>
                        </mat-form-field>
                    </div>

                    <table mat-table [dataSource]="dataSource" matSort  id="htmlData">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> No.</th>
                            <td mat-cell *matCellDef="let i = index">
                                {{ (paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1) }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> CID </th>
                            <td mat-cell *matCellDef="let row"> {{row.cid}} </td>
                        </ng-container>

                        <ng-container matColumnDef="mobile">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
                            <td mat-cell *matCellDef="let row"> {{row.mobile}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dob">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Birth Date </th>
                            <td mat-cell *matCellDef="let row"> {{row.dob | date }} </td>
                        </ng-container>

                        <ng-container matColumnDef="regTypea">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Registration </th>
                            <td mat-cell *matCellDef="let row"> {{ row.reg_type[0].regType}} </td>
                        </ng-container>


                        <ng-container matColumnDef="cardType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Card Type </th>
                            <td mat-cell *matCellDef="let row"> {{ row.card_type[0].cardType}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cardnumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Card Number </th>
                            <td mat-cell *matCellDef="let row"> {{ row.cardnumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="registration_number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Application No </th>
                            <td mat-cell *matCellDef="let row"> {{row.registration_number}} </td>
                        </ng-container>

                        <ng-container matColumnDef="file">
                            <th mat-header-cell *matHeaderCellDef> Document </th>
                            <td mat-cell *matCellDef="let row"> <a href="{{filePath}}{{row.file}}" target="_blank">View</a></td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let row"> {{row.action}} </td>
                        </ng-container>

                        <ng-container matColumnDef="created_at">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Application Date </th>
                            <td mat-cell *matCellDef="let row"> {{row.created_at | date}} </td>
                        </ng-container>

                        <ng-container matColumnDef="author">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Approved By </th>
                            <td mat-cell *matCellDef="let row"> {{row.user[0].name}} </td>
                        </ng-container>


                        <ng-container matColumnDef="actionbtn">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let row">
                                <button type="button" (click)="editApplication(row)" class="btn btn-primary btn-sm">View Details</button>
                            </td>
                        </ng-container>



                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                    </table>
                </div>
            </div>
            <mat-paginator fxFlex="100" #paginatorRef [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100,300,500,1500]">
            </mat-paginator>
        </div>
    </div>
</div>
<!-- spinner -->
<div *ngIf="showLoader">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#fff" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
    </ngx-spinner>
</div>
import { Component, OnInit } from '@angular/core';
import { SmartcardRegistrationService } from 'src/app/services/smartcardregistration.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contactdtl: any;

  constructor(public service:SmartcardRegistrationService) {
  }

  ngOnInit(): void {
    this.contact();
  }

  contact(){
    this.service.getContact()
    .subscribe({
      next: (res)=>{
        this.contactdtl = res[0];
      },
      error: (err)=>{
        alert("ERRR")
      }
    });
  }

}

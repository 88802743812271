<div class="wrapper" *ngIf="isLoggedIn">
    <app-admin_nav></app-admin_nav>
</div>

<div>
    <router-outlet></router-outlet>
</div>

<div class="footer">
    <app-footer></app-footer>
</div>


<div class="divider"></div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" template="<img src='assets/loader/spinner.gif' border-radius='5px' width='250px' />" type="ball-scale-multiple">
    <p style="font-size: 20px; color:#443e3d; font-style: italic; padding-top: 30px;">Please wait...</p>
</ngx-spinner>
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '../../services/utility.service';
import { RoleService } from '../../services/role.service';
import { Role } from '../../model/role';
import { DataService } from '../../services/data.service';
import { Person } from '../../model/person';
import { Permission } from '../../model/permission';

@Component({
  selector: 'app-role-index',
  templateUrl: './role-index.component.html',
  styleUrls: ['./role-index.component.css']
})
export class RoleIndexComponent implements OnInit {
  roleForm : UntypedFormGroup;
  actionBtn: string = 'Add';

  roles: Role;
  permissions: [];
  selectedPermissions = [];
  allSelected: any;
  addRoleFlag: boolean = false;
  editRoleFlag: boolean = false;

  people: Person[] = [];
  selectedPeople = [];
  
  permissionsL: Permission [] = [];
  role_name: string;
  short_code: string;
  data = [];


  submitted = false;
  
  userForm = new UntypedFormGroup(
    {
      role_name: new UntypedFormControl('', [Validators.required]),
    }
  );
  isAdminPage: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private roleService: RoleService,
    private dataService: DataService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private dialogRef: MatDialogRef<RoleIndexComponent>
  ) {   }
  
  ngOnInit(): void {
    this.isAdminPage = false;
    // this.roleForm = this.formBuilder.group({
    //   role_name:   ['phurpa22', [ Validators.required ]],
    //   short_code:   ['Descrition'],
    //   permissions: [''],
    //   role_id:     [''],
    //   selectedPermissions: [''],
    //   selectedPeople: ['']
    // });

    this.roleService.getAllPermissions()
    .subscribe((res) => {
        this.permissionsL = res['permissions'];
        // console.log(this.permissionsL);
        //this.selectedPermissions =  [this.permissionsL[0].id, this.permissionsL[1].id];
    });
    
    if(this.editData){
      this.editRoleFlag = true;
      this.addRoleFlag = false;
      this.roleService.findRole(this.editData.id).subscribe((data: Role)=>{
        this.roles = data['role'];
        this.permissions = data['permissions'];
        this.selectedPermissions = data['assignedPermissionsList'];
        
        console.log(data);
        this.role_name = this.editData.role_name;
        this.short_code = this.editData.short_code;

        // this.roleForm.controls["role_id"].setValue(this.roles.id);
        // this.roleForm.controls["selectedPermissions"].setValue(this.selectedPermissions);
        // this.roleForm.controls["role_name"].setValue(this.editData.role_name);
        // this.roleForm.controls["short_code"].setValue(this.editData.short_code);
        this.actionBtn = "Update";
      });
    }
    else{
      this.getAllRolePermissions();
    }
  }

  // get f() {
  //   return this.userForm.controls;
  // }

  getAllRolePermissions(){
    this.addRoleFlag = true;
    this.editRoleFlag = false;
    this.roleService.getRolePermission().subscribe((data: any)=>{
      console.log(data);
      this.roles = data['role'];
      this.permissions = data['permissions'];
      //this.selectedPermissions = data['permissions'];
    });
  }

//   tosslePerOne(all){ 
//     if (this.allSelected.selected) {  
//      this.allSelected.deselect();
//      return false;
//  }
//    if(this.roleForm.controls.permissions.value.length==this.permissions.length)
//      this.allSelected.select();
 
//  }
//    toggleAllSelection() {
//      if (this.allSelected.selected) {
//        this.roleForm.controls.permissions
//          .patchValue([...this.permissions.map(item => item), 0]);
//      } else {
//        this.roleForm.controls.userType.patchValue([]);
//      }
//    }
   
  addRole(){
    this.data = [{
      role_name:   this.role_name,
      short_code:   this.short_code,
      permissions: this.selectedPermissions
    }];
    
    this.data = this.data[0];
    //console.log(this.data);
    if(!this.editData){
      if((this.data['role_name'] !== undefined) && (this.data['permissions'].length > 0))
      {
        this.roleService.create(this.data)
        .subscribe({
          next: (res)=>{
            this.utilityService.successNotification("Successfully added");
            this.dialogRef.close('Save');
          },
          error:()=>{
            this.utilityService.errorNotification("Error: Make sure same role_name donot exist.");
          }
        })
      }else{
        this.utilityService.errorNotification("Error: Role name and permission cannot be empty.");
      }
    }else{
      this.updateRole(this.editData.id, this.data);
    }
  }

  updateRole(id,data)
  {
    this.roleService.update(id, data)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully updated");
        this.dialogRef.close('update');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in updating the role");
      }
    })
  }

  deleteRole(id){
    this.roleService.delete(id)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully deleted");
        this.roleForm.reset();
        this.dialogRef.close('Save');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in deleting the role");
      }
    })
  }

  toggleCheckAll(values: any) {
    if (values.currentTarget.checked) {
      this.selectAllItems();
    } else {
      this.unselectAllItems();
    }
  }
  
  onChange(event) {
    //debugger;
  }

  onTouched() {}

  onSelectionChange(selectedItems) {
    if (Array.isArray(selectedItems)) {
      const newList = selectedItems.map((x) => x.id);
      this.selectedPermissions = [...newList]
    //  this.onChange([...newList]);
    }
    this.onTouched();
  }

  private selectAllItems() {
    const newList = this.permissionsL.map((x) => x.id);
    this.selectedPermissions = [...newList];
    this.onChange([...newList]);
  }

  private unselectAllItems() {
    this.selectedPermissions = [];
    this.onChange([]);
  }

}

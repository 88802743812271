<div *ngIf="isLoggedIn">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button">
                    <mat-icon>menu</mat-icon>
                </a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto">
            <a routerLink="/pending" class="nav-link">
                <button  mat-raised-button color="primary"> Pending List </button>
            </a>
      
            <a routerLink="/approved" class="nav-link">
                <button  mat-raised-button color="accent"> Approved List </button>
            </a>
       
            <a routerLink="/rejected" class="nav-link">
               <button  mat-raised-button color="warn"> Rejected List </button>
            </a>
        </ul>

        <ul class="navbar-nav ml-auto">
            <button *ngIf="!isLoggedIn" mat-raised-button color="warn" (click)="loginDialog()">Login</button>
            <button *ngIf="isLoggedIn" mat-raised-button color="warn" style="margin-right: 30px;" (click)="logout()">Logout</button>
        </ul>
    </nav>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/dashboard" class="brand-link">
            <img src="assets/images/logos/logo1.png" alt="Admin Logo" class="brand-image img-circle elevation-3" style="opacity: 0.9">
            <span class="brand-text font-weight-light">&nbsp;</span>
        </a>
        <div class="sidebar">
         
            <nav class="mt-2" *ngIf="isLoggedIn">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item has-treeview menu-open">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Application
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        
                        <ul class="nav nav-treeview">
                           <!--
                            <li class="nav-item">
                                <a routerLink="/user" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>User Management</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/role" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Role Management</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/permission" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Permission Management</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/post" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Post Management</p>
                                </a>
                            </li>  
                        -->
                            <li class="nav-item">
                                <a routerLink="/dashboard" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Pending List </p>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/approved" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Approved List </p>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/rejected" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Rejected List  </p>
                                </a>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    </aside>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>

    <footer class="main-footer">
        <strong>Copyright &copy; 2022 </strong> All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
        </div>
    </footer>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Post } from '../model/Post';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type' : 'application/json; charset=utf-8',
    })
  };

 constructor(private httpClient: HttpClient,    private tokenStorageService: TokenStorageService) { }

  getAllPost(): Observable<Post[]> {
    return this.httpClient.get<Post[]>(environment.ApiURL+'post')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getAllApplications(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'smartcard')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getApprovedApplications(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'smartcard/approved')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getApprovedApplicationsDate(from:any, to:any): Observable<any> {
    const fromto = from+'|'+to;
    console.log(fromto);
    
    return this.httpClient.get<any>(environment.ApiURL+'smartcard/approvedfromto/'+fromto)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getRejecteddApplications(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'smartcard/rejected')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  findApplication(id: number): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL + 'smartcard/'+id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  getAllPostPublic(): Observable<any> {
    return this.httpClient.get<any>(environment.ApiURL+'post')
    .pipe(
      catchError(this.errorHandler)
    )
  }

  create(file: File, postImage: File, data:any, dt: string): Observable<Post> {
    const formData: FormData = new FormData();
     
    formData.append('file', file);
    formData.append('postImage', postImage);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('user_id', data.user_id);
    formData.append('dated', dt);
    // console.log(formData);

    return this.httpClient.post<any>(environment.ApiURL + 'post', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  createCatalog(id:any, files: any, data:any): Observable<any> {
    const formData: FormData = new FormData();
    if(files.length > 0) {
      for(var i=0;i< files.length;i++){
        formData.append("imgs[]",files[i]);
      }
    }
    formData.append('category_id', data.category_id);
    formData.append('description', data.description);
    formData.append('user_id', data.user_id);
    formData.append('price', data.price);
    formData.append('id', id);
    formData.append('imgCount', files.length);
    formData.append('product_name', data.product_name);
    formData.append('material', data.material);
    formData.append('weight', data.weight);
    formData.append('purpose', data.purpose);
    formData.append('producedIn', data.producedIn);
    
    return this.httpClient.post<any>(environment.ApiURL + 'catalog/createCatalog', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  updateCatalog(id: any,files:any, data:any): Observable<any> {
    const formData: FormData = new FormData();
    if(files.length > 0) {
      for(var i=0;i< files.length;i++){
        formData.append("imgs[]",files[i]);
      }
    }
    formData.append('category_id', data.category_id);
    formData.append('description', data.description);
    formData.append('user_id', data.user_id);
    formData.append('price', data.price);
    formData.append('id', id);
    formData.append('imgCount', files.length);
    formData.append('product_name', data.product_name);
    formData.append('material', data.material);
    formData.append('weight', data.weight);
    formData.append('purpose', data.purpose);
    formData.append('producedIn', data.producedIn);

    return this.httpClient.post<any>(environment.ApiURL + 'catalog/updateCatalog', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  update(id: any, data:any, dated: any): Observable<any> {
    data.dated = dated;
    return this.httpClient.put<Post>(environment.ApiURL + 'post/'+id, data, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe(catchError(this.errorHandler))
  }

  find(id: number): Observable<Post> {
    return this.httpClient.get<Post>(environment.ApiURL + 'post/'+id)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  delete(id: number){
    return this.httpClient.delete<Post>(environment.ApiURL + 'post/'+id, this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  fetchUser() {
    this.httpClient
      .get('https://api.github.com/users/thisiszoaib')
      .subscribe((res) => {
        console.log(res);
      });

      this.httpClient
      .get('https://api.github.com/users/thisiszoaib')
      .subscribe((res) => {
        console.log(res);
      });

      this.httpClient.get('https://api.github.com/users/thisiszoaib').pipe(
        concatMap(() => this.httpClient.get('https://api.github.com/users'))
      )
      .subscribe((res) => {
        console.log(res);
      });
 }

  //createNewCatelog(id: any, postImage: File, f1: any, f2: any, f3: any, data:any): Observable<any> {
  createNewCatelog(id: any, postImage: File, files: any, data:any): Observable<any> {
    const formData: FormData = new FormData();
   
    for(var i=0;i< files.length;i++){
      formData.append("imgs[]",files[i]);
      console.log( files[i]);
    }
    formData.append('postImage', postImage);
    // formData.append('f1', f1);
    // formData.append('f2', f2);
    // formData.append('f3', f3);
    formData.append('title', '');
    formData.append('description', data.description);
    formData.append('price', data.price);
    formData.append('category_id', data.category_id);
    formData.append('status', data.status);
    formData.append('user_id', data.user_id);
    formData.append('post_id', id);
    
    return this.httpClient.post<any>(environment.ApiURL + 'catalog/addUpdateCatelog', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe( catchError(this.errorHandler))
  }

  updateCatelog(id: any, data:any, postImage: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('postImage', postImage);
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('price', data.price);
    formData.append('category_id', data.category_id);
    formData.append('status', data.status);
    formData.append('user_id', data.user_id);
    formData.append('post_id', id);
 
    return this.httpClient.post<any>(environment.ApiURL + 'catalog/addUpdateCatelog', formData, 
    {
      reportProgress: true,
      responseType: 'json'
    })
    .pipe(catchError(this.errorHandler))
  }

  errorHandler(error: any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '../../services/utility.service';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-permission-index',
  templateUrl: './permission-index.component.html',
  styleUrls: ['./permission-index.component.css']
})
export class PermissionIndexComponent implements OnInit {

  permissionForm : UntypedFormGroup;
  actionBtn: string = 'Add';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private permissionService: PermissionService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public editData: any,
    private dialogRef: MatDialogRef<PermissionIndexComponent>
  ) {   }
  
  ngOnInit(): void {
    this.permissionForm = this.formBuilder.group({
      name:   ['phurpa22', [ Validators.required ]],
      description:   ['Descrition']
    });

    if(this.editData){
      this.permissionForm.controls["name"].setValue(this.editData.name);
      this.permissionForm.controls["description"].setValue(this.editData.description);
      this.actionBtn = "Update";
    }
  }

  addPermission(){
    if(!this.editData){
      if(this.permissionForm.valid){
        this.permissionService.create(this.permissionForm.value)
        .subscribe({
          next: (res)=>{
            this.utilityService.successNotification("Successfully added");
            this.permissionForm.reset();
            this.dialogRef.close('Save');
          },
          error:()=>{
            this.utilityService.errorNotification("Error: Make sure same username or email donot exist.");
          }
        })
      }
    }else{
      this.updatePermission(this.editData.id, this.permissionForm.value);
    }
  }

  updatePermission(id,data)
  {
    this.permissionService.update(id, data)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully updated");
        this.permissionForm.reset();
        this.dialogRef.close('update');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in updating the user");
      }
    })

  }

  deletePermission(id){
    this.permissionService.delete(id)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully deleted");
        this.permissionForm.reset();
        this.dialogRef.close('Save');
      },
      error:()=>{
        this.utilityService.errorNotification("Error in deleting the user");
      }
    })
  }
}

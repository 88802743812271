import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from '../../services/utility.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { PermissionIndexComponent } from '../permission-index/permission-index.component';
import { PermissionService } from '../../services/permission.service';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-permission-dashboard',
  templateUrl: './permission-dashboard.component.html',
  styleUrls: ['./permission-dashboard.component.css']
})
export class PermissionDashboardComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'description', 'created_at','action'];
  dataSource: MatTableDataSource<any>;
  showLoader: boolean;
  canCreatePermission: boolean = false;
  canEditPermission: boolean = false;
  canDeletePermission: boolean = false;
  userLoggedIn: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private utilityService: UtilityService,
    private permissionsService: PermissionService,
    private SpinnerService: NgxSpinnerService
  ) {   }
  
  ngOnInit(): void {
    this.getAllPermissions(true);
  }

  ngAfterViewInit() {
    if(this.userLoggedIn){
      this.paginator.page
        .pipe(
          tap(() => this.getAllPermissions(true))
        )
        .subscribe();
    }
  }

  editPermission(row: any){
    this.dialog.open(PermissionIndexComponent,{
      width: '60%',
      height: '60%',
      data: row
    }).afterClosed().subscribe(val=>{
      if(val === 'update'){
        this.getAllPermissions(false);
      }
    });
  }

  deletePermission(content: any, id: number)
  {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {  
      if (result === 'yes') {  
        this.deletePermissionRecord(id);  
      }  
    }, (reason) => {  
      });  
  }

  deletePermissionRecord(id){
    this.permissionsService.delete(id)
    .subscribe({
      next: (res)=>{
        this.utilityService.successNotification("Successfully deleted the permission");
        this.getAllPermissions(false);
      },
      error : ()=>{
        this.utilityService.errorNotification("Error in deleting the permission");
      }
    })
  }
  
  addPermissionDialog() {
    this.dialog.open(PermissionIndexComponent, {
      width: '60%',
      height: '60%'
    }).afterClosed().subscribe(val=>{
      this.getAllPermissions(false);
    })
  }

  getAllPermissions(displayLoader){
    if(displayLoader){
      this.showLoader = true;
    }else{
      this.showLoader = false;
    }

    this.SpinnerService.show(); 

    if(this.tokenStorageService.getToken() === null){
      this.SpinnerService.hide(); 
      this.redirectPage();
    }else{
      this.userLoggedIn = true;
    }
    this.permissionsService.getAllPermissions()
    .subscribe({
      next: (res)=>{
        this.SpinnerService.hide(); 
        this.dataSource = new MatTableDataSource(res['permissions']);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        if(res['userPermission']['createPermission'] == 1){
          this.canCreatePermission = true;
        }
        if(res['userPermission']['editPermission'] == 1){
          this.canEditPermission = true;
        }
        if(res['userPermission']['deletePermission'] == 1){
          this.canDeletePermission = true;
        }

      },
      error: (err)=>{
        console.log("Error ....")
      }
    })
  }

  redirectPage(): void {
    this.tokenStorageService.signOut();
    window.location.href=environment.webUrl;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

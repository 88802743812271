import { Component, ViewChild, OnInit } from '@angular/core';
import { TokenStorageService } from './services/token-storage.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as _ from 'lodash';
import { UserSession } from './model/userSession';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent  implements OnInit {
  isLoggedIn: boolean = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username?: string;

  userSession: UserSession;

  @ViewChild(MatPaginator) paginator!: MatPaginator; //Add ! if there is error as paginator!
  @ViewChild(MatSort) sort!: MatSort;

  userPanelAccess: boolean = false;
  postPanelAccess: boolean = false;
  rolePanelAccess: boolean = false;
  permissionPanelAccess: boolean = false; 
  subscriberUser: boolean = false;

  public screenWidth: any;
  public screenHeight: any;
  
  constructor(
      private tokenStorageService: TokenStorageService,
      private router: Router
    ) {     }

    
  ngOnInit(): void {
    // console.log("ROle ==="+this.tokenStorageService.getUser());

    if(this.tokenStorageService.getUser() !== undefined)
    {
      this.username = this.tokenStorageService.getUser().user_name;
      
      if(this.tokenStorageService.getUser().userPanelAccess == 1){
        this.userPanelAccess = true;
      }
      if(this.tokenStorageService.getUser().rolePanelAccess == 1){
        this.rolePanelAccess = true;
      }
      if(this.tokenStorageService.getUser().permissionPanelAccess == 1){
        this.permissionPanelAccess = true;
      }
      if(this.tokenStorageService.getUser().postPanelAccess == 1){
        this.postPanelAccess = true;
      }
    
      if(this.tokenStorageService.getToken() === null){
        this.isLoggedIn = false;
        this.router.navigateByUrl('/');
      }else{
        if(this.tokenStorageService.getUser().user_role === '3'){
          // console.log("ROle111 ==="+this.tokenStorageService.getUser().user_role);
          // this.subscriberUser = true;
          this.isLoggedIn = false;
        }else{
          this.isLoggedIn = true;
        }
        // this.isLoggedIn = true;
         
      }
    }else{
      this.subscriberUser = false;
      this.isLoggedIn = false;
    }
  }

}

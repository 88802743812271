import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Product } from '../services/product.service';
// import { AnimationController } from '@ionic/angular';
import { Observable } from 'rxjs';
// import { Animation } from '@ionic/angular';
import { MatPaginator } from '@angular/material/paginator';
import { CatalogService } from '../services/catalog.service';
import { LoadingService } from '../services/LoadingService';
import { SmartcardRegistrationService } from '../services/smartcardregistration.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  catalogues: any;
  filterTerm: string;
  page = 1;
  count = 0;
  pageSize = 12;
  pageSizes = [10, 20, 30, 40, 50, 60, 100];
  
  tableSizes: any = [3, 6, 9, 12];

  products: Observable<any>;
  categories = [];
  product: Product = null;
  cartAnimation: Animation;
  cartProductList = [];
  displayCart: boolean = false;
  url: string;
  showEdit: boolean = false;
  userDtl: any;
  imagePath: string;

  @ViewChild('myfab', { read: ElementRef }) cartBtn: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  loading$ = this.loader.loading$;
  relayOn: any;
  instructions: any;

  constructor(
    private catalogService: CatalogService,
    public loader: LoadingService,
    public service:SmartcardRegistrationService
    // private animationCtrl: AnimationController,
  ) {
  }
  
  ngOnInit(): void {
     this.homeMessage();
  }
  
  homeMessage(){
    this.service.homeMessage()
    .subscribe({
      next: (res)=>{
        this.instructions = res[0];
      },
      error: (err)=>{
        alert("Error..Try later ")
      }
    });
  }
 
  open(url){
    window.open(url,"_blank")
  }

  getRelayOn(){
    this.catalogService.getRelayOn()
    .subscribe({
      next: (res)=>{
        this.relayOn = res;
      }
    });
  }

  showModal: boolean;
  show()
  {
    this.showModal = true; 
  }
  hide()
  {
    this.showModal = false;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // authApiURL: 'http://localhost/smartcard_backend/public/api/auth/',
  // ApiURL: 'http://localhost/smartcard_backend/public/api/',
  // webUrl: 'http://localhost:4200',
  authApiURL: 'https://smartcardregistration.bt/smartcard_backend/public/api/auth/',
  ApiURL: 'https://smartcardregistration.bt/smartcard_backend/public/api/',
  webUrl: 'https://smartcardregistration.bt',
  MaxFileSize: 5000000,
  ImageAllowedType: ['image/png', 'image/jpeg','image/jpeg'],
  AttachmentAllowedType: ['image/png', 'image/jpeg','application/pdf'],
  //attachmentPath: 'http://localhost/smartcard_backend/public/uploads/smartcard/',
  attachmentPath: 'https://smartcardregistration.bt/smartcard_backend/public/uploads/smartcard/',
  width: '80%'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<div class="container">
        <h2>{{ actionBtn}} Permission </h2>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="permissionForm" action="">
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" placeholder="Permission name">
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input matInput formControlName="description" placeholder="Enter Description">
            </mat-form-field>
                
            <div mat-dialog-action [align]="'end'">
                <button mat-raised-button color="primary" [disabled]="permissionForm.invalid" (click)="addPermission()">{{ actionBtn}}</button>
                <button style="margin-left:5px;" mat-raised-button color="warn" mat-dialog-close>Close</button>
            </div>
        </form>
    </div>
    
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Permission } from '../model/permission';
import { environment } from '../../environments/environment.prod';

// export interface Person {
//     id: string;
//     isActive: boolean;
//     age: number;
//     name: string;
//     gender: string;
//     company: string;
//     email: string;
//     phone: string;
//     disabled?: boolean;
// }

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(private http: HttpClient) { }

    // getGithubAccounts(term: string = null) {
    //     if (term) {
    //         return this.http.get<any>(`https://api.github.com/search/users?q=${term}`).pipe(map(rsp => rsp.items));
    //     } else {
    //         return of([]);
    //     }
    // }

    // getAlbums() {
    //     return this.http.get<any[]>('https://jsonplaceholder.typicode.com/albums');
    // }

    // getPhotos() {
    //     return this.http.get<any[]>('https://jsonplaceholder.typicode.com/photos');
    // }

    getPeople(term: string): Observable<Permission[]> {
        let items = getMockPermission();
        if (term) {
            items = items.filter(x => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        }
        return of(items).pipe(delay(500));
    }

    dcproducts(): any {
        let url = environment.webUrl+'/';
        return [
            {
            "id":1,
            "price":109.95,
            "title": 'Siliver chusey',
            "description":"Kids Koma available for sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",
            "category":"",
            "image": url+"assets/images/dcimages/1.jpeg"
         },
         {
            "id":2,
            "price":100,
            "title": 'Kids Koma',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",
            "category":"",
            "image":url+"assets/images/dcimages/2.jpeg"
         },
         {
            "id":3,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",
            "category":"",
            "image":url+"assets/images/dcimages/3.jpeg"
         },
         {
            "id":4,
            "price":115,
            "title": 'Kids Koma',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/5.jpeg"
         },
         {
            "id":5,
            "price":115,
            "title": 'Kids Koma',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/5.jpeg"
         },
         {
            "id":6,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/6.jpeg"
         },
         {
            "id":7,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/7.jpeg"
         },
         {
            "id":8,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/8.jpeg"
         },
         {
            "id":9,
            "price":115,
            "title": 'Siliver ting',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/9.jpeg"
         },
         {
            "id":10,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/10.jpeg"
         },
         {
            "id":11,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/11.jpeg"
         },
         {
            "id":12,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/1.jpeg"
         },
         {
            "id":13,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/2.jpeg"
         },
         {
            "id":14,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/3.jpeg"
         },
         {
            "id":15,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/4.jpeg"
         },
         {
            "id":16,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/5.jpeg"
         },
         {
            "id":17,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/6.jpeg"
         },
         {
            "id":18,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/7.jpeg"
         },
         {
            "id":19,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/8.jpeg"
         },
         {
            "id":20,
            "price":115,
            "title": 'Siliver chusey',
            "description":"Item available for whole/retail sale. Location Paro town. Free delivery to all dzongkhag . Interested buyers can directly message or call us at 777435559/77271696",           
            "category":"",
            "image":url+"assets/images/dcimages/10.jpeg"
         }
        ]
    }

    getDCProduct(term: number): Observable<Permission[]> {
        let items = this.dcproducts();
        if (term) {
            items = items.filter(x => x.id.toLocaleLowerCase().indexOf(term) > -1);
        }
        return of(items).pipe(delay(500));
    }

    catalogues(): any {
        return [
            {
                "id": 1,
                "item_code": 'B001001',
                "category": "Wood Product",
                "name": "ZAFOR: Cups made from special Burrs",
                "photo": "assets/images/catalogue/1.png",
                "cost": "USD $10",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            },
            {
                "id": 2,
                "item_code": 'B001002',
                "category": "Wood Product",
                "name": "TSAMDER: Serving bowl",
                "photo": "assets/images/catalogue/2.png",
                "cost": "USD $12",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            },
            {
                "id": 3,
                "item_code": 'B001003',
                "category": "Wood Product",
                "name": "LHUNGZED: Begging bowl",
                "photo": "assets/images/catalogue/3.png",
                "cost": "USD $15",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            }, {
                "id": 4,
                "item_code": 'B001004',
                "category": "Wood Product",
                "name": "ZAFOR: Cups made from special Burrs",
                "photo": "assets/images/catalogue/4.png",
                "cost": "USD $10",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            },
            {
                "id": 5,
                "item_code": 'B001005',
                "category": "Wood Product",
                "name": "TSAMDER: Serving bowl",
                "photo": "assets/images/catalogue/5.png",
                "cost": "USD $12",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            },
            {
                "id": 6,
                "item_code": 'B001006',
                "category": "Wood Product",
                "name": "LHUNGZED: Begging bowl",
                "photo": "assets/images/catalogue/1.png",
                "cost": "USD $15",
                "details" : "Gelong Zecha is made mostly from burrs and do not have lid. In the past, the cup was used to serve special dish to lama.",
                "utility" : "To serve tea, curry and ara.",
                "material": "Burr or wood from Maple, Alder, and wild Avacodo. Plated with Silver.",
                "type"  : "Traditional"
            }
        ];
    }


    getStaff(): any {
    return [
        {
            "id": 1,
            "name": "Lam Kezang Chhoephel",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/4.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : "Chief Executive Officer"
        },
        {
            "id": 2,
            "name": "Pema Lodey",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/1.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Sr/ Adm/Planning Officer'
        },
        {
            "id": 3,
            "name": "Tshering Peldon",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/2.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Sr. Communication Officer'
        },
        {
            "id": 4,
            "name": "Cheki Dorji",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/3.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Cluster Developmenet Officer'
        },
        {
            "id": 5,
            "name": "Dorji Drukpa",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/default.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Craft Market Development Officer'
        },
        {
            "id": 6,
            "name": "Karna Chettri",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/default.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Account Officer'
        },
        {
            "id": 7,
            "name": "Karma",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/default.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Sr. Adm/HR/QM/PO'
        },
        {
            "id": 8,
            "name": "Karma",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/default.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Coomunication Officer'
        },
        {
            "id": 9,
            "name": "Karma",
            "email": "karma@aa.com",
            "photo": "assets/images/staff/default.jpeg",
            "contact": "xxx-xxx-xxxx",
            "designation" : 'Driver'
        }
    ];
    }
}

function getMockPermission() {
    return [
        {
            "id": 1,
            "name": "admin_panel_access",
            "description": "Show Admin Panel",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-08T09:27:37.000000Z"
        },
        {
            "id": 2,
            "name": "users_access",
            "description": "User Home page",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-10T14:10:14.000000Z"
        },
        {
            "id": 3,
            "name": "user_edit",
            "description": "Edit User",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-11T13:12:40.000000Z"
        },
        {
            "id": 4,
            "name": "user_delete",
            "description": "Delete User",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-11T13:13:06.000000Z"
        },
        {
            "id": 5,
            "name": "user_create",
            "description": "Create User",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-31T05:35:30.000000Z"
        },
        {
            "id": 6,
            "name": "user_show",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 7,
            "name": "roles_access",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 8,
            "name": "role_edit",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 9,
            "name": "role_delete",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 10,
            "name": "role_create",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 11,
            "name": "role_show",
            "description": "Show role index",
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-08T09:27:11.000000Z"
        },
        {
            "id": 12,
            "name": "permissions_access",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 13,
            "name": "permission_edit",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 14,
            "name": "permission_delete",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 15,
            "name": "permission_create",
            "description": null,
            "created_at": "2022-03-07T11:40:18.000000Z",
            "updated_at": "2022-03-07T11:40:18.000000Z"
        },
        {
            "id": 17,
            "name": "posts_access",
            "description": null,
            "created_at": "2022-03-07T12:06:01.000000Z",
            "updated_at": "2022-03-07T12:29:25.000000Z"
        },
        {
            "id": 18,
            "name": "post_create",
            "description": null,
            "created_at": "2022-03-07T12:32:30.000000Z",
            "updated_at": "2022-03-07T12:32:30.000000Z"
        },
        {
            "id": 19,
            "name": "post_edit",
            "description": "Edit Post",
            "created_at": "2022-03-07T12:32:38.000000Z",
            "updated_at": "2022-04-01T07:33:37.000000Z"
        },
        {
            "id": 20,
            "name": "post_delete",
            "description": null,
            "created_at": "2022-03-07T12:32:46.000000Z",
            "updated_at": "2022-03-07T12:32:46.000000Z"
        },
        {
            "id": 21,
            "name": "post_show",
            "description": null,
            "created_at": "2022-03-07T13:55:33.000000Z",
            "updated_at": "2022-03-07T13:56:11.000000Z"
        },
        {
            "id": 22,
            "name": "tasks_access",
            "description": "ddaddd",
            "created_at": "2022-03-08T08:01:27.000000Z",
            "updated_at": "2022-03-08T09:15:27.000000Z"
        },
        {
            "id": 29,
            "name": "post_verify",
            "description": null,
            "created_at": "2022-03-09T08:16:21.000000Z",
            "updated_at": "2022-03-09T08:40:59.000000Z"
        },
        {
            "id": 30,
            "name": "post_approve",
            "description": null,
            "created_at": "2022-03-09T08:18:06.000000Z",
            "updated_at": "2022-03-09T08:41:09.000000Z"
        },
        {
            "id": 31,
            "name": "citizenDetails",
            "description": null,
            "created_at": "2022-03-23T06:42:11.000000Z",
            "updated_at": "2022-03-23T06:42:11.000000Z"
        },
        {
            "id": 34,
            "name": "Approval",
            "description": "Desc",
            "created_at": "2022-03-27T14:06:53.000000Z",
            "updated_at": "2022-03-27T14:06:53.000000Z"
        },
        {
            "id": 35,
            "name": "Approval2",
            "description": "Desc",
            "created_at": "2022-03-27T14:06:59.000000Z",
            "updated_at": "2022-03-27T14:06:59.000000Z"
        },
        {
            "id": 36,
            "name": "ASAS",
            "description": "assas",
            "created_at": "2022-03-28T15:05:13.000000Z",
            "updated_at": "2022-03-28T15:05:13.000000Z"
        },
        {
            "id": 37,
            "name": "23434",
            "description": "34242",
            "created_at": "2022-03-31T01:46:19.000000Z",
            "updated_at": "2022-03-31T01:46:19.000000Z"
        }
    ]
}


